import { configureStore } from '@reduxjs/toolkit'
import reportsReducer from './ReportsSlice'
import userReducer from './UserSlice'
import toolsReducer from './ToolsSlice'

export const store = configureStore({
  reducer: {
    reportsSlice: reportsReducer,
    userSlice: userReducer,
    toolsSlice: toolsReducer,
  },
})