import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Button from '../components/ui/Button'
import { toast } from 'react-toastify'
import { NoPaddingCardWrapper } from '../components/ui/CardWrapper'
import { FetchReport } from '../redux/ReportsApis'
import { UpdateReportDetails } from '../utils/HelperFunctions';
import Loader from '../components/Loader';
import TextInput from '../components/ui/TextInput'
import { seletecReportLoader } from '../redux/ReportsSlice';

function EditReport({currentToolFields, isOpen}) {
  const report = useSelector((state) => state?.reportsSlice?.selectedReport)
  const reportLoader = useSelector(seletecReportLoader)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const userToken = localStorage.getItem('user_id')
  const [loading, setLoading] = useState(false)
  const [navigated, setNavigated] = useState(false)

  const [dynamicFields, setDynamicFields] = useState(() => 
    currentToolFields.reduce((acc, field) => {
      acc[field.name] = '';
      return acc;
    }, {})
  );

  const [staticFields, setStaticFields] = useState({
    comment: '',
    report: null,
  });

  useEffect(() => {
    if (!report?.report) {
      dispatch(FetchReport({ id: report?.id }));
    }

    const updatedDynamicFields = currentToolFields.reduce((acc, field) => {
      acc[field.name] = (report.fields && report.fields[field.name]) || report[field.name] || '';
      return acc;
    }, {});

    setDynamicFields(updatedDynamicFields);
    setStaticFields({ comment: report?.comment || '', report: report?.report || null});
  
  }, [dispatch, report, currentToolFields]);

  useEffect(() => {
    if (!userToken) {
      navigate('/')
    }

    //  Scroll to top of component upon every mount.
    window.scrollTo(0, 0);
  }, [userToken, navigate])


  const handleUpdateReportDetails = async () => {
    setLoading(true)

    const tool_fields = currentToolFields.map((field) => ({
      id: field.id || '',
      value: dynamicFields[field.name] || '',
    }));

    const dataToSend = {
      ...staticFields,
      id: report.id,
      tool_fields
    };

    const detailUpdated = await UpdateReportDetails({ dataToSend })
    dispatch(FetchReport({ id: report.id }))
    if (detailUpdated) {
      toast.success("Report updated successfully!")
    }
    setNavigated(true)
  }

  useEffect(() => {
    if (JSON.stringify(report) === '{}') {
      navigate('/scribe')
    }
  }, [navigate, report])

  useEffect(() => {
    if (!reportLoader && navigated) {
      setLoading(false)
      setNavigated(false)
      isOpen(false)
    }
  }, [reportLoader, navigate, navigated, report.row_number, isOpen])

  function formatTimestamp() {
    const timestamp = new Date(report?.created_at);

    const month = timestamp.getMonth();
    const year = timestamp.getFullYear();
    const day = timestamp.getDate();
    const formattedTimestamp = `${day}.${month + 1}.${year}`;
    return formattedTimestamp;
  }

  const handleInputChange = ({ key, value }) => {
    if (currentToolFields.some(field => field.name === key)) {
      setDynamicFields(prevState => ({
        ...prevState,
        [key]: value
      }));
    } else {
      setStaticFields(prevState => ({
        ...prevState,
        [key]: value
      }));
    }
  }

  return (
    <NoPaddingCardWrapper edit>
      {loading ? (
        <div className='flex justify-center items-center min-h-[30vh]'>
          <Loader />
        </div>
      ) : (
        <>
            <div className='flex md:flex-row flex-col gap-4 justify-between border-b border-b-1 border-b-[#E5E7EC] p-[20px] px-0 sm:p-[32px] sm:px-0'>
              <div className='flex sm:items-center flex-col sm:flex-row'>
                <div className='ml-0 mt-0 md:text-left text-center'>
                  <p className='text-black text-[16px] font-SuisseIntlLight font-semibold'>Report #{report.row_number}</p>
                  <p className='text-[#868C98] text-[12px] mt-[4px] font-SuisseIntlLight font-normal'>Uploaded: {formatTimestamp()}</p>
                </div >
              </div >
              <div className='flex sm:flex-row flex-col items-center md:justify-center '>
                <Button onClick={handleUpdateReportDetails} size='large'>
                  Save
                </Button>
              </div>
            </div >
            
            <div className='p-[20px] px-0'>
              <div className='flex flex-col gap-[20px]'>
                {currentToolFields.map((field) => (
                  <TextInput
                    key={field.name}
                    name={field.name}
                    label={field.name.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase())}
                    placeholder={`Enter ${field.name.replace(/_/g, ' ')}`}
                    value={dynamicFields[field.name] || ''}
                    onChange={(e) => handleInputChange({ key: field.name, value: e.target.value })}
                    type='text'
                  />
                ))}
              </div>
              <div className='mt-[20px]'>
                <TextInput
                  textArea={true}
                  name={'comment'}
                  label={'Comment'}
                  placeholder={'Add a comment (optional)'}
                  value={staticFields.comment || ''}
                  onChange={(e) => handleInputChange({ key: 'comment', value: e.target.value })}
                  type='text'
                />
              </div>
            </div>
        </>
      )
      }
    </NoPaddingCardWrapper>
  )
}

export default EditReport