import React from 'react'

export function CardWrapper({ children, dashedBorder, className }) {
  return (
    <div className={` ${className}  ${dashedBorder ? 'border border-dashed cursor-pointer':'border'} w-full flex flex-col  items-center text-center`}>
      {children}
    </div>
  )
}

export function MainCardWrapper ({children, addingReport}){
  return(
    <div className={`${addingReport ? "w-full" : "mt-4 p-[12px] sm:p-[28px] w-[90vw] md:w-[80vw] card-shadow"}`}>
      {children}
    </div>
  )
}

export function NoPaddingCardWrapper ({children, isDrawer, edit}){
  return(
    <div className={`${isDrawer || edit ? 'mt-0 max-w-[100%]': 'mt-4 card-shadow max-w-[764px]'} w-[90vw] md:w-[80vw] lg:w-[60vw]`}>
      {children}
    </div>
  )
}
export function NoShadowCardWrapper ({children, isDrawer= false}){
  return (
    <div className={`${isDrawer ? 'mt-0': 'mt-4'} max-w-[764px] w-[90vw] md:w-[80vw] lg:w-[60vw]`}>
      {children}
    </div>
  )
}
//