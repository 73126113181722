import { Button } from "@/components/shadcn-ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/shadcn-ui/card";

import React, { useEffect, useState } from "react";
import GeneralIcon from "../images/SVG/generalcon.svg";
import billingIcon from "../images/SVG/billingIcon.svg";
import autoChangeIcon from "../images/SVG/autoChangeIcon.svg";
import planIcon from "../images/SVG/planIcon.svg";
import templateIcon from "../images/SVG/templateIcon.svg";
import { signOut } from "aws-amplify/auth";
import { Link, useNavigate } from "react-router-dom";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "@/components/shadcn-ui/tabs";
import { Label } from "@/components/shadcn-ui/label";
import { Input } from "@/components/shadcn-ui/input";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/shadcn-ui/select";
import { Dialog, DialogContent, DialogTrigger } from "@/components/shadcn-ui/dialog";
import AddToken from "@/components/AddToken";
import { Table, TableBody, TableCell, TableHeader, TableRow } from "@/components/shadcn-ui/table";
import deleteIcon from '../images/SVG/deleteIcon.svg'
import { Textarea } from "@/components/shadcn-ui/textarea";

const tabTrigger = [
  {
    id: 1,
    title: "General",
    icon: GeneralIcon,
    value: "general",
  },
  {
    id: 2,
    title: "Billing",
    icon: billingIcon,
    value: "billing",
  },
  {
    id: 3,
    title: "Auto Change",
    icon: autoChangeIcon,
    value: "autoChange",
  },
  {
    id: 4,
    title: "Template",
    icon: templateIcon,
    value: "template",
  },
  {
    id: 5,
    title: "Plans",
    icon: planIcon,
    value: "plans",
  },
];
const autoChangeSubmenu = [
  { id: 1, title: "Vocabulary", value: "vocabulary" },
  { id: 2, title: "Text Modules", value: "text-modules" },
];

const Profile = () => {
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState(false);
  const [activeTab, setActiveTab] = useState("general");
  const handleLogout = async () => {
    await signOut();
    localStorage.removeItem("user_id");
    navigate("/");
  };
  const toggleAutoChangeSubmenu = () => {
    setExpanded((prev) => !prev);
    // Automatically select the first submenu item when "autoChange" is clicked
    if (!expanded) {
      setActiveTab(autoChangeSubmenu[0].value); // Selects "Sub-item 1" initially
    }
  };

  useEffect(() => {
    // Close submenu when switching to a tab outside of autoChange
    if (activeTab !== "autoChange" && !autoChangeSubmenu.some((item) => item.value === activeTab)) {
      setExpanded(false);
    }
  }, [activeTab]);

  return (
    <div className="mt-4 md:p-[12px] p-0 sm:p-[28px] mb-10 w-[90vw] md:w-[80vw] md:bg-white md:border md:border-[#E4E4E7] md:shadow-[#0000000A] rounded-[20px]">
      <Card className="border-none shadow-none w-full h-[100vh] bg-transparent">
        <CardHeader className="p-0 mb-3 md:hidden">
          <CardTitle className="font-bold">Settings</CardTitle>
        </CardHeader>
        <Tabs className="w-full h-full" value={activeTab} onValueChange={(value) => setActiveTab(value)}>
          <div className="w-full h-full grid md:grid-cols-6 grid-cols-1 gap-5">
            <div className="col-span-2 md:h-fit h-[82vh] flex justify-between flex-col">
              <Card className="relative">
              <TabsList
                  data-orientation="vertical"
                  className="flex flex-col overflow-y-scroll md:overflow-hidden h-full justify-start gap-3 bg-transparent dark:bg-transparent"
                >
                  {tabTrigger.map((tab) => (
                    <>
                      <TabsTrigger
                        key={tab.id}
                        className="w-full justify-start data-[state=active]:bg-[#F6F8FA]"
                        value={tab.value}
                        onClick={
                          tab.value === "autoChange"
                            ? toggleAutoChangeSubmenu
                            : () => setActiveTab(tab.value)
                        }
                      >
                        <img src={tab.icon} className="mr-3" alt={tab.title} />
                        <span className="font-SuisseIntlLight font-semibold">
                          {tab.title}
                        </span>
                      </TabsTrigger>

                      {/* Render submenu items below "Auto Change" */}
                      {tab.value === "autoChange" && expanded && (
                        <div className="p-0 shadow-none md:relative z-50 top-10 md:top-0 left-[150px] md:left-[-30px] flex flex-col gap-3 w-2/3 bg-white md:bg-transparent rounded-[10px]">
                          {autoChangeSubmenu.map((subItem, index) => (
                            <TabsTrigger
                              key={subItem.id}
                              className={`w-full font-semibold justify-start text-sm data-[state=active]:bg-[#F6F8FA] ${
                                index === 0 && !activeTab ? "bg-[#F6F8FA]" : ""
                              }`}
                              value={subItem.value}
                              onClick={() => setActiveTab(subItem.value)}
                            >
                              {subItem.title}
                            </TabsTrigger>
                          ))}
                        </div>
                      )}
                    </>
                  ))}
                </TabsList>
              </Card>
              <Button
                className="mt-3 w-full relative"
                variant="outline"
                onClick={handleLogout}
              >
                Logout
              </Button>
            </div>
            <div className="md:col-span-4 col-span-2 h-screen md:h-fit">
            <TabsContent value="general">
                <General />
              </TabsContent>
              <TabsContent value="billing">
                <Billing />
              </TabsContent>
              <TabsContent value="vocabulary">
                <Vocabulary/>
              </TabsContent>
              <TabsContent value="text-modules">
                <TextModules/>
              </TabsContent>
              <TabsContent value="template">
                <h1>template</h1>
              </TabsContent>
              <TabsContent value="plans">
                <Plans />
              </TabsContent>

            </div>
          </div>
        </Tabs>
      </Card>
    </div>
  );
};

export default Profile;

const General = () => {
  const allModelsLanguageList = [
    { name: "English", value: "en" },
    { name: "German (Switzerland)", value: "german (switzerland)" },
    { name: "Finnish", value: "fi" },
    { name: "German", value: "de" },
    { name: "Spanish", value: "es" },
    { name: "French", value: "fr" },
    { name: "Italian", value: "it" },
    { name: "Portuguese", value: "pt" },
    { name: "Dutch", value: "nl" },
    { name: "Hindi", value: "hi" },
    { name: "Japanese", value: "ja" },
    { name: "Korean", value: "ko" },
    { name: "Polish", value: "pl" },
    { name: "Russian", value: "ru" },
    { name: "Turkish", value: "tr" },
    { name: "Ukrainian", value: "uk" },
    { name: "Vietnamese", value: "vi" },
  ];
  let timeZones = [
    "Europe/Andorra",
    "Asia/Dubai",
    "Asia/Kabul",
    "Europe/Tirane",
    "Asia/Yerevan",
    "Pacific/Pago_Pago",
    "Europe/Vienna",
    "Australia/Lord_Howe",
    "Antarctica/Macquarie",
    "Australia/Hobart",
    "Australia/Currie",
    "Australia/Melbourne",
    "Australia/Sydney",
    "Australia/Broken_Hill",
    "Australia/Brisbane",
    "Australia/Lindeman",
    "Australia/Adelaide",
    "Australia/Darwin",
    "Australia/Perth",
    "Australia/Eucla",
    "Asia/Baku",
    "America/Barbados",
    "Asia/Dhaka",
    "Europe/Brussels",
    "Europe/Sofia",
    "Atlantic/Bermuda",
    "Asia/Brunei",
    "America/La_Paz",
    "America/Noronha",
    "America/Belem",
    "America/Fortaleza",
    "America/Recife",
    "America/Araguaina",
    "America/Maceio",
    "America/Bahia",
    "America/Sao_Paulo",
    "America/Campo_Grande",
    "America/Cuiaba",
    "America/Santarem",
    "America/Porto_Velho",
    "America/Boa_Vista",
    "America/Manaus",
    "America/Eirunepe",
    "America/Rio_Branco",
    "America/Nassau",
    "Asia/Thimphu",
    "Europe/Minsk",
    "America/Belize",
    "America/St_Johns",
    "America/Halifax",
    "America/Glace_Bay",
    "America/Moncton",
    "America/Goose_Bay",
    "America/Blanc-Sablon",
    "America/Toronto",
    "America/Nipigon",
    "America/Thunder_Bay",
    "America/Iqaluit",
    "America/Pangnirtung",
    "America/Atikokan",
    "America/Winnipeg",
    "America/Rainy_River",
    "America/Resolute",
    "America/Rankin_Inlet",
    "America/Regina",
    "America/Swift_Current",
    "America/Edmonton",
    "America/Cambridge_Bay",
    "America/Yellowknife",
    "America/Inuvik",
    "America/Creston",
    "America/Dawson_Creek",
    "America/Fort_Nelson",
    "America/Vancouver",
    "America/Whitehorse",
    "America/Dawson",
    "Indian/Cocos",
    "Europe/Zurich",
    "Africa/Abidjan",
    "Pacific/Rarotonga",
    "America/Santiago",
    "America/Punta_Arenas",
    "Pacific/Easter",
    "Asia/Shanghai",
    "Asia/Urumqi",
    "America/Bogota",
    "America/Costa_Rica",
    "America/Havana",
    "Atlantic/Cape_Verde",
    "America/Curacao",
    "Indian/Christmas",
    "Asia/Nicosia",
    "Asia/Famagusta",
    "Europe/Prague",
    "Europe/Berlin",
    "Europe/Copenhagen",
    "America/Santo_Domingo",
    "Africa/Algiers",
    "America/Guayaquil",
    "Pacific/Galapagos",
    "Europe/Tallinn",
    "Africa/Cairo",
    "Africa/El_Aaiun",
    "Europe/Madrid",
    "Africa/Ceuta",
    "Atlantic/Canary",
    "Europe/Helsinki",
    "Pacific/Fiji",
    "Atlantic/Stanley",
    "Pacific/Chuuk",
    "Pacific/Pohnpei",
    "Pacific/Kosrae",
    "Atlantic/Faroe",
    "Europe/Paris",
    "Europe/London",
    "Asia/Tbilisi",
    "America/Cayenne",
    "Africa/Accra",
    "Europe/Gibraltar",
    "America/Godthab",
    "America/Danmarkshavn",
    "America/Scoresbysund",
    "America/Thule",
    "Europe/Athens",
    "Atlantic/South_Georgia",
    "America/Guatemala",
    "Pacific/Guam",
    "Africa/Bissau",
    "America/Guyana",
    "Asia/Hong_Kong",
    "America/Tegucigalpa",
    "America/Port-au-Prince",
    "Europe/Budapest",
    "Asia/Jakarta",
    "Asia/Pontianak",
    "Asia/Makassar",
    "Asia/Jayapura",
    "Europe/Dublin",
    "Asia/Jerusalem",
    "Asia/Kolkata",
    "Indian/Chagos",
    "Asia/Baghdad",
    "Asia/Tehran",
    "Atlantic/Reykjavik",
    "Europe/Rome",
    "America/Jamaica",
    "Asia/Amman",
    "Asia/Tokyo",
    "Africa/Nairobi",
    "Asia/Bishkek",
    "Pacific/Tarawa",
    "Pacific/Enderbury",
    "Pacific/Kiritimati",
    "Asia/Pyongyang",
    "Asia/Seoul",
    "Asia/Almaty",
    "Asia/Qyzylorda",
    "Asia/Qostanay", 
    "Asia/Aqtobe",
    "Asia/Aqtau",
    "Asia/Atyrau",
    "Asia/Oral",
    "Asia/Beirut",
    "Asia/Colombo",
    "Africa/Monrovia",
    "Europe/Vilnius",
    "Europe/Luxembourg",
    "Europe/Riga",
    "Africa/Tripoli",
    "Africa/Casablanca",
    "Europe/Monaco",
    "Europe/Chisinau",
    "Pacific/Majuro",
    "Pacific/Kwajalein",
    "Asia/Yangon",
    "Asia/Ulaanbaatar",
    "Asia/Hovd",
    "Asia/Choibalsan",
    "Asia/Macau",
    "America/Martinique",
    "Europe/Malta",
    "Indian/Mauritius",
    "Indian/Maldives",
    "America/Mexico_City",
    "America/Cancun",
    "America/Merida",
    "America/Monterrey",
    "America/Matamoros",
    "America/Mazatlan",
    "America/Chihuahua",
    "America/Ojinaga",
    "America/Hermosillo",
    "America/Tijuana",
    "America/Bahia_Banderas",
    "Asia/Kuala_Lumpur",
    "Asia/Kuching",
    "Africa/Maputo",
    "Africa/Windhoek",
    "Pacific/Noumea",
    "Pacific/Norfolk",
    "Africa/Lagos",
    "America/Managua",
    "Europe/Amsterdam",
    "Europe/Oslo",
    "Asia/Kathmandu",
    "Pacific/Nauru",
    "Pacific/Niue",
    "Pacific/Auckland",
    "Pacific/Chatham",
    "America/Panama",
    "America/Lima",
    "Pacific/Tahiti",
    "Pacific/Marquesas",
    "Pacific/Gambier",
    "Pacific/Port_Moresby",
    "Pacific/Bougainville",
    "Asia/Manila",
    "Asia/Karachi",
    "Europe/Warsaw",
    "America/Miquelon",
    "Pacific/Pitcairn",
    "America/Puerto_Rico",
    "Asia/Gaza",
    "Asia/Hebron",
    "Europe/Lisbon",
    "Atlantic/Madeira",
    "Atlantic/Azores",
    "Pacific/Palau",
    "America/Asuncion",
    "Asia/Qatar",
    "Indian/Reunion",
    "Europe/Bucharest",
    "Europe/Belgrade",
    "Europe/Kaliningrad",
    "Europe/Moscow",
    "Europe/Simferopol",
    "Europe/Kirov",
    "Europe/Astrakhan",
    "Europe/Volgograd",
    "Europe/Saratov",
    "Europe/Ulyanovsk",
    "Europe/Samara",
    "Asia/Yekaterinburg",
    "Asia/Omsk",
    "Asia/Novosibirsk",
    "Asia/Barnaul",
    "Asia/Tomsk",
    "Asia/Novokuznetsk",
    "Asia/Krasnoyarsk",
    "Asia/Irkutsk",
    "Asia/Chita",
    "Asia/Yakutsk",
    "Asia/Khandyga",
    "Asia/Vladivostok",
    "Asia/Ust-Nera",
    "Asia/Magadan",
    "Asia/Sakhalin",
    "Asia/Srednekolymsk",
    "Asia/Kamchatka",
    "Asia/Anadyr",
    "Asia/Riyadh",
    "Pacific/Guadalcanal",
    "Indian/Mahe",
    "Africa/Khartoum",
    "Europe/Stockholm",
    "Asia/Singapore",
    "America/Paramaribo",
    "Africa/Juba",
    "Africa/Sao_Tome",
    "America/El_Salvador",
    "Asia/Damascus",
    "America/Grand_Turk",
    "Africa/Ndjamena",
    "Indian/Kerguelen",
    "Asia/Bangkok",
    "Asia/Dushanbe",
    "Pacific/Fakaofo",
    "Asia/Dili",
    "Asia/Ashgabat",
    "Africa/Tunis",
    "Pacific/Tongatapu",
    "Europe/Istanbul",
    "America/Port_of_Spain",
    "Pacific/Funafuti",
    "Asia/Taipei",
    "Europe/Kiev",
    "Europe/Uzhgorod",
    "Europe/Zaporozhye",
    "Pacific/Wake",
    "America/New_York",
    "America/Detroit",
    "America/Kentucky/Louisville",
    "America/Kentucky/Monticello",
    "America/Indiana/Indianapolis",
    "America/Indiana/Vincennes",
    "America/Indiana/Winamac",
    "America/Indiana/Marengo",
    "America/Indiana/Petersburg",
    "America/Indiana/Vevay",
    "America/Chicago",
    "America/Indiana/Tell_City",
    "America/Indiana/Knox",
    "America/Menominee",
    "America/North_Dakota/Center",
    "America/North_Dakota/New_Salem",
    "America/North_Dakota/Beulah",
    "America/Denver",
    "America/Boise",
    "America/Phoenix",
    "America/Los_Angeles",
    "America/Anchorage",
    "America/Juneau",
    "America/Sitka",
    "America/Metlakatla",
    "America/Yakutat",
    "America/Nome",
    "America/Adak",
    "Pacific/Honolulu",
    "America/Montevideo",
    "Asia/Samarkand",
    "Asia/Tashkent",
    "America/Caracas",
    "Asia/Ho_Chi_Minh",
    "Pacific/Efate",
    "Pacific/Wallis",
    "Pacific/Apia",
    "Africa/Johannesburg",
  ];
  return (
    <Card className="border-none shadow-none lg:w-[75%] md:w-full md:bg-white bg-transparent">
      <CardHeader className="pt-0 md:px-6 px-0">
        <CardTitle>General</CardTitle>
        <CardDescription className='font-normal mt-2 text-[14px] text-[#71717a]'>Manage Your Profile Settings</CardDescription>
      </CardHeader>
      <CardContent className="md:px-6 px-0">
        <Card>
          <CardContent className="p-3">
            <form action="" className="flex flex-col gap-3 flex-wrap">
              <div>
                <div className="flex justify-between items-center mb-2">
                  <Label className="font-SuisseIntlLight font-semibold">Email</Label>
                  <Link className="font-SuisseIntlLight font-semibold text-[14px] text-[#71717A] underline">
                    Change
                  </Link>
                </div>
                <Input className="font-semibold placeholder:font-SuisseIntlLight placeholder:font-semibold" placeholder="george@mail.com" type="email" />
              </div>
              <div className="flex gap-3 items-center justify-between">
                <div className="w-full flex flex-col gap-2">
                  <Label className="font-semibold font-SuisseIntlLight">Language</Label>
                  <Select>
                    <SelectTrigger>
                      <SelectValue className="font-semibold placeholder:font-semibold" placeholder="Select a Language" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectGroup>
                        {allModelsLanguageList.map((lang) => (
                          <SelectItem value={lang.value}>
                            {lang.name}
                          </SelectItem>
                        ))}
                      </SelectGroup>
                    </SelectContent>
                  </Select>
                </div>
                <div className="w-full flex flex-col gap-2">
                  <Label className="font-semibold">Time Zone</Label>
                  <Select>
                    <SelectTrigger>
                      <SelectValue placeholder="Select a time zone" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectGroup>
                        {timeZones.map((zone, index) => (
                          <SelectItem value={zone} key={index}>
                            {zone}
                          </SelectItem>
                        ))}
                      </SelectGroup>
                    </SelectContent>
                  </Select>
                </div>
              </div>
            </form>
          </CardContent>
        </Card>
      </CardContent>
    </Card>
  );
};

const Billing = () => {
  const invoices = [
    {
      date: "10 Oct 2024, 18:56",
      totalTokens: "+1000 Tokens",
      name: "Invoice",
    },
    {
      date: "10 Oct 2024, 18:56",
      totalTokens: "+750 Tokens",
      name: "Invoice",
    },
    {
      date: "10 Oct 2024, 18:56",
      totalTokens: "-780 Tokens",
      name: "Report Generated",
    },
    {
      date: "10 Oct 2024, 18:56",
      totalTokens: "-200 Tokens",
      name: "Report Generated",
    },
    {
      date: "10 Oct 2024, 18:56",
      totalTokens: "+160 Tokens",
      name: "Invoice",
    },
    {
      date: "10 Oct 2024, 18:56",
      totalTokens: "+100 Tokens",
      name: "Invoice",
    },
  ]  
  return (
  <Card className="border-none shadow-none lg:w-[75%] md:w-full md:bg-white bg-transparent">
    <CardHeader className="pt-0 md:px-6 px-0">
      <CardTitle>Billing</CardTitle>
      <CardDescription className="text-[14px] mt-2 font-normal text-[#71717a]">Manage your balance and keep track of your transaction history</CardDescription>
    </CardHeader>
    <CardContent className="md:p-6 p-0">
      <Card className="flex p-3 justify-between items-center">
          <div>
            <CardDescription className="text-[14px] font-semibold text-[#71717a]">Token Balance</CardDescription>
            <CardTitle className="mt-2">1,026</CardTitle>
          </div>
          <div>
          <Dialog>
            <DialogTrigger>
          <Button className='font-medium'>Add Tokens</Button>
            </DialogTrigger>
            <DialogContent className="rounded-3xl">
              <AddToken/>
            </DialogContent>
          </Dialog>
          </div>
      </Card>
      <Card className="border-none shadow-none">
        <CardDescription className="text-black font-SuisseIntlLight font-semibold text-[14px] my-3">Transaction History</CardDescription>
        <Card>
        <Table>
      <TableBody>
        {invoices.map((invoice, index) => (
          <>
          <TableRow key={index} className="hidden md:flex justify-between">
            <TableCell className="text-[12px] text-[#09090B] font-semibold">{invoice.date}</TableCell>
            <TableCell className="text-[12px] text-[#09090B] font-semibold">{invoice.name}</TableCell>
            <TableCell className="text-right text-[12px] text-[#09090B] font-semibold">{invoice.totalTokens}</TableCell>
          </TableRow>
          <TableRow key={index} className="md:hidden">
            <TableCell className="text-[12px] text-[#09090B] font-semibold">
              <div>
                <p className="text-[#71717A] font-SuisseIntlLight font-medium text-xs">{invoice.name}</p>
                <h1 className="font-SuisseIntlLight font-semibold text-base">{invoice.date}</h1>
              </div>
            </TableCell>
            <TableCell className="text-right text-base text-[#09090B] font-semibold">{invoice.totalTokens}</TableCell>
          </TableRow>
          </>
        ))}
      </TableBody>
    </Table>
        </Card>
      </Card>

    </CardContent>
  </Card>

  );
};



const Plans = () =>{
  const planning = [
    {
      id:1,
      name:'Basic',
      description:'Good for beginners',
      pricePerWord: '0.03',
      minAmount: null,
    },
    {
      id:2,
      name:'Standard',
      description:'Good for frequent use',
      pricePerWord: '0.02',
      minAmount: '10,000',
    },
    {
      id:3,
      name:'Premium',
      description:'Good for professionals',
      pricePerWord: '0.01',
      minAmount: '25,000',
    },
  ]
  return(
    <Card className="border-none shadow-none lg:w-[75%] md:w-full md:bg-white bg-transparent">
      <CardHeader className="pt-0 md:px-6 px-0">
        <CardTitle>Plans</CardTitle>
        <CardDescription className="font-medium text-[14px]">Choose the plan according to your needs</CardDescription>
      </CardHeader>
      <CardContent className='flex gap-3 flex-col md:p-6 p-0 md:pt-0 md:pb-3'>
        {planning.map((plan)=>(
          <Card key={plan.id} className="p-3 pt-0 md:p-0">
            <div className="flex justify-between items-center p-3 flex-wrap gap-3 md:gap-0 md:border-b md:border-b-[#E4E4E7]">
              <div>
                <CardTitle className="text-[22px]">{plan.name}</CardTitle>
                <CardDescription className="font-semibold text-[12px]">{plan.description}</CardDescription>
              </div>
              <div>
              <Dialog className="hidden md:flex">
            <DialogTrigger className="hidden md:flex">
          <Button className='font-medium hidden md:flex'>Get Tokens</Button>
            </DialogTrigger>
            <DialogContent className="rounded-3xl">
              <AddToken/>
            </DialogContent>
          </Dialog>
              </div>
            </div>
            <CardContent className='flex justify-between items-center md:pt-6 pt-3 md:p-6 p-3 flex-wrap gap-3 md:gap-0'>
              <div className="md:w-3/6 w-[45%] border-r border-r-[#E4E4E7]">
                <CardTitle className="text-[20px]">{plan.pricePerWord}</CardTitle>
                <CardDescription className="text-[12px] font-semibold">Price Per Word</CardDescription>
              </div>
              <div className="md:w-3/6 md:px-5">
                <CardTitle className="text-[20px]">{plan.minAmount === null ? 'No' : `${plan.minAmount} CFH` }</CardTitle>
                <CardDescription className="text-[12px] font-semibold">Minimum Amount</CardDescription>
              </div>
            </CardContent>
              <Dialog className="md:hidden flex w-full">
            <DialogTrigger className="md:hidden flex w-full">
          <Button className='font-medium w-full md:hidden flex'>Get Tokens</Button>
            </DialogTrigger>
            <DialogContent className="rounded-3xl">
              <AddToken/>
            </DialogContent>
          </Dialog>
          </Card>
        ))}
      </CardContent>
      <CardFooter className='block px-0 md:px-6 mt-3 md:mt-0'>
        <p className="text-[14px] font-SuisseIntlLight font-normal">Enterprise customers can <span className="underline font-semibold">get in touch</span> with us here for a tailor made deal</p>
      </CardFooter>
    </Card>
  )
}


const Vocabulary = ()=>{
  const vocab= [
    {
      id:1,
      value1: 'Pediatory',
      value2:'Kinderheilkunde',
      icon: deleteIcon,
    },
    {
      id:2,
      value1: 'Pediatory',
      value2:'Kinderheilkunde',
      icon: deleteIcon,
    },
    {
      id:3,
      value1: 'Pediatory',
      value2:'Kinderheilkunde',
      icon: deleteIcon,
    },
  ]
  return(
    <Card className="border-none shadow-none lg:w-[75%] md:w-full md:bg-white bg-transparent">
      <CardHeader className="pt-0 md:px-6 px-0 flex flex-row justify-between items-center">
        <div>
        <CardTitle className='font-semibold'>Vocabulary</CardTitle>
        <CardDescription className="font-medium mt-2 text-[14px] text-[#71717A]">Mark the words that will be added to the reports in a different way</CardDescription>
        </div>
        <div className="md:block hidden">
          <Button>Add Word</Button>
        </div>
      </CardHeader>
      <CardContent className="md:p-6 px-0">
        <Card className=''>
          <Table className="md:flex hidden">
            <TableHeader className="p-3 md:p-0">
              <TableRow>
                <TableCell className='font-SuisseIntlLight font-semibold text-[#71717A]'>Find</TableCell>
                <TableCell className='font-SuisseIntlLight font-semibold text-[#71717A]'>Replace</TableCell>
              </TableRow>
            </TableHeader>
            <TableBody>
              {vocab.map((vocab)=>(
                <TableRow key={vocab.id}>
                <TableCell>
                <Input type='text' value={vocab.value1} className="w-full font-semibold"/>
                </TableCell>
                <TableCell>
                <div className="flex gap-3 items-center">
                  <Input type='text' value={vocab.value2} className='w-full font-semibold'/>
                  <Button variant='outline' className='flex-none'>
                    <img src={vocab.icon} alt="Delete icon" />
                  </Button>
                </div>
                </TableCell>
              </TableRow>
              ))}
            </TableBody>
          </Table>
          <CardContent className="md:hidden p-2">
            <Button className='w-full mb-2'>Add Word</Button>
              <Table className="border-t border-[#e4e4e7]">
                <TableBody>
                {vocab.map((vocab)=>(
                <TableRow key={vocab.id}>
                <TableCell className="p-2">
                <Input type='text' value={vocab.value1} className="w-full font-semibold rounded-b-none"/>
                  <Input type='text' value={vocab.value2} className='w-full font-semibold rounded-t-none'/>
                </TableCell>
                <TableCell className='p-2 text-center align-top w-16'>
                  <Button variant='outline' className='flex-none'>
                    <img src={vocab.icon} alt="Delete icon" />
                  </Button>
                </TableCell>
              </TableRow>
              ))}
                </TableBody>
              </Table>
          </CardContent>
        </Card>
      </CardContent>
    </Card>
  )
}


const TextModules = ()=>{
  const modules = [
    {
      id:1,
      label1: 'Plan',
      icon: deleteIcon,
      inputValue1: 'MedGuide101',
      label2: 'Replace',
      inputValue2: 'Take one tablet daily with water before meals. Avoid alcohol. Consult if symptoms persist.',
      hasMargin: true,
    },
    {
      id:2,
      label1: 'Plan',
      icon: deleteIcon,
      inputValue1: 'MedGuide101',
      label2: 'Replace',
      inputValue2: 'Take one tablet daily with water before meals. Avoid alcohol. Consult if symptoms persist.',
      hasMargin: false,
    },
  ]
  return (
    <Card className="border-none shadow-none lg:w-[75%] md:w-full md:bg-white bg-transparent">
      <CardHeader className="pt-0 md:px-6 px-0 flex flex-row justify-between items-center">
        <div>
        <CardTitle className='font-SuisseIntlLight font-semibold'>Text Modules</CardTitle>
        <CardDescription className="font-medium mt-2 text-[14px] text-[#71717A]">Mark the words that are converted to full text in the report</CardDescription>
        </div>
        <div>
          <Button className='font-semibold'>Add Text Module</Button>
        </div>
      </CardHeader>
      <CardContent>
        <Card>
            {modules.map((mode)=>(
              <CardContent key={mode.id} className={`${mode.hasMargin === true ? 'mt-3' : ''}`}>
                <Label className="font-semibold">{mode.label1}</Label>
                <div className="flex justify-between gap-3 my-2 items-center">
                  <Input value={mode.inputValue1} className="font-semibold"/>
                  <Button variant="outline">
                  <img src={mode.icon} alt="delete"/>
                  </Button>
                </div>
                <Label className="font-semibold ">{mode.label2}</Label>
                <Textarea placeholder="Type your message here." className='resize-none lg:h-[170px] h-[220px] md:h-[120px] mt-2 font-semibold' value={mode.inputValue2} />
              </CardContent>
            ))}
        </Card>
      </CardContent>
      </Card>
  )
}
