import React, { useEffect, useRef, useState, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import NoReportFound from "../components/NoReportFound";
import Notification from "../components/Notification";
import StatusDropdown from "../components/StatusDropdown";
import Pagination from "../components/Pagination";
import { useDispatch, useSelector } from "react-redux";
import { FetchReport, FetchBackendReports } from "../redux/ReportsApis";
import { selectAllReports, selectInProgressReports, selectProcessingReports, selecteCompletedReports, setSelectedReport, selectAllReportsLoading, selectProcessingReportsLoading, selectInProgressReportsLoading, selecteCompletedReportsLoading, selecteGeneratedReports, selectGeneratedReportsLoading } from "../redux/ReportsSlice";
import Button from "../components/ui/Button";
import Loader from "../components/Loader";
import { MainCardWrapper } from "../components/ui/CardWrapper";
import closeCircle from "../images/x-circle.png";
import TextInput from "../components/ui/TextInput";
import { getuser, handleUpdateStatus } from "../utils/HelperFunctions";
import { FilterIcon, SearchIcon } from "../components/ui/SvgIcons";
import { pageSize } from "../utils/constantVariables";
import { setPayingCustomer, setServiceName, setTemplates, setTrialEndDate, setWordCount } from "../redux/UserSlice";
import { useCurrentTool } from "@/utils/ToolHelpers";
import { toast } from "react-toastify";
import { X } from "lucide-react";
import Report from "./Report";
import { formatDate } from "@/utils/date";
import AddReport from "./AddReport";
import CustomTable from "@/components/CustomTable";
import CompletedIcon from "../images/SVG/reportTableIcons/completed.svg";
import urgentIcon from "../images/SVG/reportTableIcons/urgent.svg";
import generatedIcon from "../images/SVG/reportTableIcons/generated.png";
import inProgressIcon from "../images/SVG/reportTableIcons/InProgress.svg";
import { Dialog, DialogContent, DialogTrigger, } from "@/components/shadcn-ui/dialog";
import { DropdownMenu, DropdownMenuContent, DropdownMenuTrigger } from "@/components/shadcn-ui/dropdown-menu";
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger } from "@/components/shadcn-ui/select";
import { Drawer, DrawerClose, DrawerContent, DrawerDescription, DrawerHeader, DrawerTitle } from "@/components/shadcn-ui/drawer";
import sortIcon from '../images/SVG/sortIcon.svg'

function debounce(func, timeout = 300) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
}

function countFiltersApplied(filterValues) {
  let filters = 0;
  for (var key in filterValues) {
    if (filterValues[key] && filterValues[key] !== "") {
      filters++;
    }
  }
  return filters === 0 ? null : filters;
}

function Reports() {
  const selectedTabRef = useRef(localStorage.getItem("status") || "all");
  const [page, setPage] = useState(1);
  const [showNotification, setShowNotification] = useState(false);
  const reports = {
    all: useSelector(selectAllReports),
    processing: useSelector(selectProcessingReports),
    generated: useSelector(selecteGeneratedReports),
    "in-progress": useSelector(selectInProgressReports),
    completed: useSelector(selecteCompletedReports),
  };
  const loading = {
    all: useSelector(selectAllReportsLoading),
    processing: useSelector(selectProcessingReportsLoading),
    generated: useSelector(selectGeneratedReportsLoading),
    "in-progress": useSelector(selectInProgressReportsLoading),
    completed: useSelector(selecteCompletedReportsLoading),
  };

  const isModelOpen = useRef(false);
  const [modelOpenState, setModelOpenState] = useState(isModelOpen.current);
  const [searchUserName, setSearchUserName] = useState("");
  const [searchIconClicked, setSearchIconClicked] = useState(false);
  const userToken = localStorage.getItem("user_session");
  const userId = localStorage.getItem("user_id");
  const report = useSelector((state) => state?.reportsSlice?.selectedReport);
  const { currentToolFields, generation_name, toolInputs } = useCurrentTool();

  useEffect(() => {
    document.body.style.overflow = modelOpenState ? "hidden" : "unset";
  }, [modelOpenState]);
  
  const [tabs, setTabs] = useState([]);

  useEffect(()=>{
    setTabs([
      {
        name: `All ${generation_name.toLowerCase()}s`,
        active: true,
        value: "all",
      },
      {
        name: "Processing",
        active: false,
        value: "processing",
      },
      {
        name: "Generated",
        active: false,
        value: "generated",
      },
      {
        name: "In Progress",
        active: false,
        value: "in-progress",
      },
      {
        name: "Completed",
        active: false,
        value: "completed",
      },
    ])
  }, [generation_name])

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const toolFieldHeaders = currentToolFields.map((field) => ({
    Header: field.name
      .replace(/_/g, " ")
      .replace(/\b\w/g, (char) => char.toUpperCase()),
    accessor: field.name,
  }));

  useEffect(() => {
    if (!userId) {
      navigate("/");
    }
  }, [userId, navigate]);

  const EmptyFilterMode = ({ reset, filters }) => {
    return reset || Object.values(filters).every((value) => value === "");
  };

  const getInitialFilters = useCallback(() => {
    const savedFilters = localStorage.getItem("filterValues");
    if (savedFilters && savedFilters !== "undefined") {
      return JSON.parse(savedFilters);
    }

    return currentToolFields?.reduce(
      (acc, field) => {
        acc[field.name] = "";
        return acc;
      },
      { comment: "" }
    );
  }, [currentToolFields]);

  const [activeFilters, setActiveFilters] = useState(getInitialFilters);
  const [filterValues, setFilterValues] = useState(getInitialFilters);

  const handleFilterSearch = useCallback(
    ({
      search,
      reset,
      resetSearchBar = false,
      searchUserName,
      dispatch,
      page,
    }) => {
      const statuses = [
        "all",
        "processing",
        "in-progress",
        "completed",
        "generated",
      ];
      const filters = getInitialFilters();
      if (!!localStorage.getItem("user_id")) {
        const userId = localStorage.getItem("user_id");
        if (!search && !resetSearchBar) {
          search = searchUserName;
        }
        if (search && !EmptyFilterMode({ reset, filters })) {
          statuses.forEach((item) =>
            dispatch(
              FetchBackendReports({
                userId,
                status: item,
                page,
                pageSize,
                search,
                filters,
                currentToolFields
              })
            )
          );
          setActiveFilters({
            ...filters,
          });
        } else if (search) {
          statuses.forEach((item) =>
            dispatch(
              FetchBackendReports({
                userId,
                status: item,
                page,
                pageSize,
                search,
                currentToolFields
              })
            )
          );
        } else if (EmptyFilterMode({ reset, filters })) {
          statuses.forEach((item) =>
            dispatch(
              FetchBackendReports({ userId, status: item, page, pageSize, currentToolFields })
            )
          );
        } else {
          statuses.forEach((item) =>
            dispatch(
              FetchBackendReports({
                userId,
                status: item,
                page,
                pageSize,
                filters,
                currentToolFields
              })
            )
          );
          setActiveFilters({
            ...filters,
          });
        }
      }
    },
    [getInitialFilters, currentToolFields]
  );

  useEffect(() => {
    if(currentToolFields.length === 0) return;
    handleFilterSearch({ search: searchUserName, dispatch, page });
    // eslint-disable-next-line
  }, [page, dispatch, currentToolFields]);

  useEffect(() => {
    try {
      const userId = localStorage.getItem("user_id");
      if (!userId || userId === "undefined") {
        return;
      }
      const getUserData = async () => {
        const UserData = await getuser({ user_id: userId });
        if (UserData) {
          dispatch(setWordCount(UserData.words));
          dispatch(setServiceName(UserData.service_name));
          dispatch(setPayingCustomer(UserData.paying_customer));
          dispatch(setTrialEndDate(UserData.trial_end_date));
          dispatch(setTemplates(UserData.templates));
        }
      };
      getUserData();
    } catch (error) {
      console.log("User Data not found");
      toast.error("Failed to fetch user details. Please try again.");
    }
  }, [userToken, dispatch]);

  const handleTabChange = async (tabType) => {
    let updatedTabs = [];
    tabs?.forEach((tab) => {
      if (tab.name === tabType) {
        localStorage.setItem("status", tab.value);
        selectedTabRef.current = tab.value;
        updatedTabs.push({
          name: tab.name,
          active: true,
          value: tab.value,
        });
      } else {
        updatedTabs.push({
          name: tab.name,
          active: false,
          value: tab.value,
        });
      }
    });
    setTabs(updatedTabs);
    setPage(1);
  };

  // const handleReportClicked = (report) => {
  //   if (report?.status === 'processing') {
  //     setShowNotification(true)
  //     setTimeout(() => {
  //       setShowNotification(false);
  //     }, 3000);
  //     return
  //   }
  //   else if (!report.report_fetched) {
  //     return
  //   }
  //   else {
  //     let updatedReport = { ...report }
  //     if (report && report.report) {
  //       updatedReport.report = updatedReport.report?.replace(/placeholder_name/g, report?.patient_name);
  //       updatedReport.report = updatedReport.report?.replace(/placeholder_doctor/g, report?.doctor_name);
  //       updatedReport.report = updatedReport.report?.replace(/placeholder_clinic/g, report?.clinic_name);
  //     }
  //     dispatch(setSelectedReport(updatedReport))
  //     navigate(`/scribe/${updatedReport.row_number}`)
  //   }
  // }
  // eslint-disable-next-line no-unused-vars
  const handleRetry = async ({ id }) => {
    await handleUpdateStatus({ documentId: id, status: "retry" });
    dispatch(FetchReport({ id, currentToolFields }));
  };

  const getReportsComponent = () => {
    const selectedTabReports = reports[selectedTabRef.current];
    const totalCount = selectedTabReports?.totalCount || 0;
    const startRange = (page - 1) * pageSize + 1;
    const endRange = Math.min(page * pageSize, totalCount);

    const columns = [
      {
        Header: "ID",
        accessor: "row_number",
      },
      ...toolFieldHeaders,
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value }) => {
          let Icon;
          if (value === "generated") {
            Icon = generatedIcon;
          } else if (value === "completed") {
            Icon = CompletedIcon; // Success icon
          } else if (value === "failed") {
            Icon = urgentIcon; // Error icon
          } else if (value === "in-progress") {
            Icon = inProgressIcon; // Error icon
          } else {
            Icon = inProgressIcon;
          }

          return (
            <div className="border border-[#e3e4e9] flex justify-center gap-1 w-fit cursor-pointer bg-white rounded-md py-1 px-3 pr-4 lg:pr-3">
              <img src={Icon} alt={value} style={{ objectFit: "contain" }} />
              <span>{value}</span>
            </div>
          );
        },
      },
      {
        Header: "Date & Time",
        accessor: "created_at",
        Cell: ({ value }) =>
          value
            ? new Date(value).toLocaleString("en-US", {
                month: "2-digit",
                day: "2-digit",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit",
              })
            : "",
      },
    ];

    const handleReportDrawer = (rowNumber) => {
      // Find the matching report by row_number
      const report = selectedTabReports?.reports?.find(
        (report) => report.row_number === rowNumber
      );

      if (!report || !report.report_fetched) {
        console.log("Report not found");
        return;
      }

      // Check if the status is "processing"
      if (report?.status === "processing") {
        setShowNotification(true);
        setTimeout(() => {
          setShowNotification(false);
        }, 3000);
        return;
      }

      let updatedReport = { ...report };
      // If the report exists and contains placeholders, replace them
      if (report && report.report) {
        currentToolFields.forEach((field) => {
          const placeholder = `placeholder_${field.name}`;
          updatedReport.report = updatedReport.report?.replace(
            new RegExp(placeholder, "g"),
            (report.fields && report.fields[field.name]) || report[field.name] || ""
          );
        });
      }

      dispatch(setSelectedReport(updatedReport));
    };

    if (selectedTabReports?.reports?.length > 0) {
      return (
        <>
          <Drawer direction="right">
            <CustomTable
              selectedTabReports={selectedTabReports?.reports}
              columns={columns}
              onClickFunction={(rowNumber) => handleReportDrawer(rowNumber)}
              handleRetry={handleRetry}
            />
            {/* {
            selectedTabReports?.reports?.map((report, index) => {
              return (
                <DrawerTrigger key={report.id} onClick={() => { handleReportDrawer(report) }} className={`${report.report_fetched ? "cursor-pointer" : "cursor-not-allowed"} block w-full`}>
                  <CustomTable/>
                  <TableCell
                    record={report}
                    last={selectedTabReports?.reports.length - 1 === index}
                    handleRetry={handleRetry}
                  />
                </DrawerTrigger>
              )
            })
          } */}
            <DrawerContent className="md:max-w-[40%] ml-auto h-full bg-white overflow-y-scroll overflow-x-hidden scroll-smooth">
              <DrawerHeader className="relative flex justify-between items-center">
                <div>
                  <DrawerTitle
                    className="text-black text-[20px] font-medium space-x-1 cursor-pointer capitalize"
                    onClick={() => navigate(`/scribe/${report.row_number}`)}
                  >
                    {report.fields?.patient_name || report.patient_name}
                  </DrawerTitle>
                  <DrawerDescription>
                    {generation_name} {report.row_number} , {formatDate(report.created_at)}
                  </DrawerDescription>
                </div>
                <DrawerClose className="">
                  <Button variant="ghost" size="icon" className="h-6 w-6">
                    <X className="h-6 w-6 text-black" />
                    <span className="sr-only">Close</span>
                  </Button>
                </DrawerClose>
              </DrawerHeader>
              <div className="">
                <Report isDrawer />
              </div>
            </DrawerContent>
          </Drawer>
          {selectedTabReports?.totalCount > pageSize && (
            <div
              className={`flex flex-col sm:flex-row  justify-between items-center md:items-center ${
                selectedTabReports.totalCount > pageSize ? "pt-[20px]" : "mt-4"
              }`}
            >
              <div className="text-[#868C98] max-w-[50%] text-sm sm:items-center hidden sm:flex overflow-hidden">
                <p className=" mr-2 whitespace-nowrap overflow-hidden">
                  Showing {<span className="font-bold">{startRange}</span>} -{" "}
                  {<span className="font-bold">{endRange}</span>} of{" "}
                  {<span className="font-bold">{totalCount}</span>} {generation_name.toLowerCase()}s
                </p>
              </div>
              <Pagination
                total={selectedTabReports.totalCount}
                totalPages={Math.ceil(selectedTabReports?.totalCount / 50)}
                pageSize={pageSize}
                page={page}
                setPage={setPage}
                className="mt-2 sm:mt-0 flex justify-center"
              />{" "}
            </div>
          )}
          {!(selectedTabReports?.totalCount > pageSize) && (
            <div
              className={`flex-col sm:flex-row  justify-between items-center hidden sm:flex md:items-center ${
                selectedTabReports.totalCount > pageSize ? "pt-[20px]" : "mt-4"
              }`}
            >
              <div className="text-[#868C98] max-w-[50%] text-sm sm:items-center hidden sm:flex overflow-hidden">
                <p className=" mr-2 whitespace-nowrap ">
                  Showing {<span className="font-bold">{startRange}</span>} -{" "}
                  {<span className="font-bold">{endRange}</span>} of{" "}
                  {<span className="font-bold">{totalCount}</span>} {generation_name.toLowerCase()}s
                </p>
              </div>
            </div>
          )}
        </>
      );
    } else {
      return <NoReportFound/>;
    }
  };

  // eslint-disable-next-line
  const debouncedHandleSearchUserName = useCallback(
    debounce(({ search }) => {
      handleFilterSearch({ search, dispatch, page: 1 });
      setPage(1);
    }, 500),
    []
  );

  const handleFilterInputChange = (e) => {
    const { name, value } = e.target;

    setFilterValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleModalToggle = () => {
    const isOpen = isModelOpen.current;
    isModelOpen.current = !isOpen;
    if (isOpen) {
      setFilterValues({
        ...activeFilters,
      });
    }
    setModelOpenState(!isOpen);
  };

  const handleSearchIconClicked = (clicked) => {
    setSearchIconClicked(clicked);
  };

  const resetFilter = () => {
    setSearchUserName("");

    const filters = currentToolFields?.reduce(
      (acc, field) => ({
        ...acc,
        [field.name]: "",
      }),
      { comment: "" }
    );

    setFilterValues(filters);
    setActiveFilters(filters);
    localStorage.removeItem("filterValues");
    handleFilterSearch({ search: "", reset: true, dispatch, page: 1 });
    setPage(1);
  };

  const handleSearchUserName = (e) => {
    const { value } = e.target;
    setSearchUserName(value);
    debouncedHandleSearchUserName({ search: value });
  };

  const handleCloseIcon = () => {
    setSearchUserName("");
    handleFilterSearch({
      search: "",
      reset: false,
      resetSearchBar: true,
      searchUserName,
      dispatch,
      page: 1,
    });
    setPage(1);
  };
  const getTextInput = () => {
    return (
      <TextInput
        name="search_name"
        placeholder={"Search..."}
        value={searchUserName}
        onChange={handleSearchUserName}
        type="text"
        width="200px"
        useWidthOnMobile={false}
        {...(!searchUserName
          ? {}
          : {
              postIcon: (
                <img
                  src={closeCircle}
                  alt=""
                  height={16}
                  width={16}
                  className="cursor-pointer"
                  onClick={handleCloseIcon}
                />
              ),
            })}
      />
    );
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);

    const filters = currentToolFields?.reduce((acc, field) => {
      acc[field.name] = formData.get(field.name) || "";
      return acc;
    }, {});

    filters.comment = formData.get("comment") || "";

    isModelOpen.current = false;
    setModelOpenState(isModelOpen.current);
    setFilterValues(filters);
    localStorage.setItem("filterValues", JSON.stringify(filters));
    handleFilterSearch({ dispatch, page: 1 });
    setPage(1);
  };

  const getFilterModel = () => {
    return (
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className=" md:block bg-white flex flex-col max-w-[315px] pt-[14px] px-[14px] sm:rounded-[15px] w-full sm:w-[420px] sm:max-h-min sm:min-h-min"
      >
        <div className="sm:flex-none flex-auto">
          <form
            onSubmit={handleFormSubmit}
            className="flex flex-col pb-0 h-full sm:justify-center"
          >
            <div className="flex items-center gap-3 mb-3">
              <TextInput
                name={"Date Start"}
                // label={field.name.replace(/_/g, " ").replace(/\b\w/g, char => char.toUpperCase())}
                placeholder={`Date Start`}
                type="text"
                value={""}
                onChange={handleFilterInputChange}
              />
              <TextInput
                name={"Date end"}
                // label={field.name.replace(/_/g, " ").replace(/\b\w/g, char => char.toUpperCase())}
                placeholder={`Date End`}
                type="text"
                value={""}
                onChange={handleFilterInputChange}
              />
            </div>
            <div className="flex flex-col gap-[10px]">
              {currentToolFields?.map((field) => (
                <TextInput
                  key={field.name}
                  name={field.name}
                  // label={field.name.replace(/_/g, " ").replace(/\b\w/g, char => char.toUpperCase())}
                  placeholder={`${field.name.replace(/_/g, " ")}`}
                  type="text"
                  value={filterValues[field.name]}
                  onChange={handleFilterInputChange}
                />
              ))}
              <TextInput
                textArea={true}
                name={"comment"}
                placeholder="Add a comment (optional)"
                type="text"
                value={filterValues.comment}
                onChange={handleFilterInputChange}
              />
            </div>
            <div className="py-[15px] flex flex-col justify-center gap-[12px]">
              <Button
                type="submit"
                className="font-SuisseIntlLight font-medium"
              >
                Apply Filters
              </Button>
              {Object.values(activeFilters).some((value) => value) && (
                <Button
                  type="button"
                  variant="light"
                  className="font-SuisseIntlLight font-semibold text-black"
                  onClick={resetFilter}
                >
                  Reset Filters
                </Button>
              )}
            </div>
          </form>
        </div>
      </div>
    );
  };
  const count = countFiltersApplied(activeFilters);

  return (
    <div>
      <div className="flex justify-between items-center md:hidden mt-3">
        <div>
          <Link
            className="text-[#71717A] text-xs font-SuisseIntlLight font-semibold"
            onClick={() => navigate(-1)}
          >
            Back
          </Link>
          <h2 className="font-SuisseIntl font-extrabold text-xl">Scribe AI</h2>
        </div>
        <div>
          <Dialog>
            <DialogTrigger>
              <Button className="text-sm">Add {generation_name}</Button>
            </DialogTrigger>
            <DialogContent addingReport>
              <AddReport addingReport  generation_name={generation_name} toolInputs={toolInputs}/>
            </DialogContent>
          </Dialog>
        </div>
      </div>
      <MainCardWrapper>
        <div className="flex justify-between items-center pb-[28px] border-b-[1px] border-[#E5E7EC]">
          <div className="w-full md:w-fit">
            <StatusDropdown
              tabs={tabs}
              selected={selectedTabRef.current}
              handler={handleTabChange}
              reports={true}
              showReportCount={true}
            />
          </div>
          <div className="mt-[4px] flex items-center gap-3 sm:mt-0">
            {!searchIconClicked ? (
              <div className="flex flex-row justify-between items-center max-h-full">
                <div className="flex justify-end gap-2">
                  <div className="hidden sm:block relative h-[42px] w-[209px]">
                    {getTextInput()}
                  </div>
                  <div>
                    <button
                      className="flex ml-[10px] sm:ml-0 sm:hidden items-center gap-[10px] text-[14px] justify-start border-[#E5E7EC] text-[#525866] border-[1px] rounded-[10px] py-[10px] px-[12px] w-[44px] h-[42px]"
                      onClick={() => {
                        handleSearchIconClicked(true);
                      }}
                    >
                      <SearchIcon />
                    </button>
                  </div>
                  <Select className="w-full">
                    <SelectTrigger className="rounded-[10px] h-[42px]">
                    <img src={sortIcon} alt="sort icon" className="md:hidden max-w-none" />
                    <p className="hidden md:block">Sort By</p>
                    </SelectTrigger>
                    <SelectContent>
                      <SelectGroup>
                        <SelectItem value="asc">Asending Order</SelectItem>
                        <SelectItem value="desc">Desending Order</SelectItem>
                      </SelectGroup>
                    </SelectContent>
                  </Select>
                  <DropdownMenu>
                    <DropdownMenuTrigger>
                      <Button
                        className="p-0 flex-none focus:border-none hover:bg-transparent hidden sm:flex items-center gap-[10px] text-[14px] justify-start"
                        variant="light"
                      >
                        <span>Filters</span>
                        {count ? <span className="ml-1">({count})</span> : null}
                      </Button>
                      <Button
                        variant="light"
                        className="block h-full relative sm:hidden items-center gap-[10px]"
                        onClick={handleModalToggle}
                      >
                        <div>
                          {count ? (
                            <span class="circle mobile right-[-0.45rem] bottom-[-0.45rem] absolute">
                              <span>{count}</span>
                            </span>
                          ) : null}
                          <FilterIcon />
                        </div>
                      </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent className="w-[320px] relative right-10 md:right-28 top-3 rounded-[14px]">
                      {getFilterModel()}
                    </DropdownMenuContent>
                  </DropdownMenu>
                </div>
              </div>
            ) : (
              <div className="flex gap-3">
                {getTextInput()}
                <button
                  className=" border-[#E5E7EC] border-[1px] rounded-[10px] px-2 h-[42px]"
                  onClick={() => {
                    handleSearchIconClicked(false);
                  }}
                >
                  <X className="text-[#525866]" />
                </button>
              </div>
            )}
            <Dialog className="hidden md:flex">
              <DialogTrigger className="hidden md:flex">
                <Button className="hidden md:flex">
                  <span className="text-[16px] md:text-[20px] mr-[8px]">+</span>
                  <span className="text-[14px]">Add {generation_name}</span>
                </Button>
              </DialogTrigger>
              <DialogContent
                className="h-fit border shadow-none rounded-[25px]"
                addingReport
              >
                <AddReport addingReport generation_name={generation_name} toolInputs={toolInputs}/>
              </DialogContent>
            </Dialog>
          </div>
        </div>
        <div>
          <div className="md:min-h-[320px] sm:min-w-full flex flex-col justify-center items-center">
            {loading[selectedTabRef.current] ? (
              <div className="w-full h-full min-h-[30vh] flex justify-center items-center">
                <Loader />
              </div>
            ) : (
              <div className="w-full h-full justify-center items-center flex flex-col">
                {getReportsComponent()}
              </div>
            )}
          </div>
        </div>
        {showNotification && (
          <Notification
            text={"This report is being generated"}
            color={"bg-black"}
          />
        )}
      </MainCardWrapper>
    </div>
  );
}

export default Reports;
