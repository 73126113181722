import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchAuthSessionWithLogout } from '../utils/authSession';
import { toast } from 'react-toastify';
import { selectToolFields, selectSelectedTool } from '@/redux/ToolsSlice';

export const FetchBackendReports = createAsyncThunk('fetchReportsFromBackend', async ({ userId, status, page, pageSize, filters, search, currentToolFields }, { rejectWithValue }) => {
  try {
    const tableName = process.env.REACT_APP_TABLE_NAME
    const endPoint = process.env.REACT_APP_AWS_API_ENDPOINT

    const dataToSend = {
      user_id: userId,
      status: status,
      tableName: tableName,
      filters: filters,
      search: search,
      tool_fields: currentToolFields
    }
    const accessToken = await fetchAuthSessionWithLogout();
    if (!accessToken) {
      return []
    }
    let response = await fetch(`${endPoint}/get-reports/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken.toString()}`,
      },
      body: JSON.stringify(dataToSend)
    })
    response = await response.json()
    if (response.error) {
      console.log("Error is ", response.error);
      return []
    }
    const filteredData = []
    response.reports?.forEach((report) => {
      filteredData.push(report)
    })
    const length = filteredData.length
    const start = (page - 1) * pageSize
    
    const end = start + pageSize
    
    if (start < 0) {
      return []
    }

    if (end > length) {
      const slicedData = filteredData.slice(start)
      const dataObj = { data: slicedData, length: length }

      return {
        data: dataObj,
        tabType: status
      }
    }

    const slicedData = filteredData.slice(start, end)
    const dataObj = { data: slicedData, length: length }
    return {
      data: dataObj,
      tabType: status
    }
  } catch (error) {
    if (!error.message === "Your trial period has ended. Please upgrade to a paying plan.") {
      toast.error("Failed to fetch reports. Please try again.");
    }
    return rejectWithValue(error.message);
  }
});

export const FetchReportTemplates = createAsyncThunk('fetchReportTemplates', async (_, { rejectWithValue }) => {
  try {
    const endPoint = process.env.REACT_APP_AWS_API_ENDPOINT

    const accessToken = await fetchAuthSessionWithLogout();
    if (!accessToken) {
      return
    }
    let response = await fetch(`${endPoint}/report-templates`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${accessToken.toString()}`,
      },
    })
    response = await response.json()
    if (response.error) {
      console.log("Error is ", response.error);
      return []
    }
    return response
  } catch (error) {
    toast.error("Failed to fetch report templates. Please try again.");
    return rejectWithValue(error.message);
  }
});

export const FetchReport = createAsyncThunk('fetchReport', async ({ id, row_number, currentToolFields }, { rejectWithValue, getState }) => {
  try {
    const endPoint = process.env.REACT_APP_AWS_API_ENDPOINT
    console.log("Document id is ", id);
    const dataToSend = {
      id,
      row_number,
      tool_fields: currentToolFields
    }
    const accessToken = await fetchAuthSessionWithLogout();
    if (!accessToken) {
      return
    }

    const state = getState();
    const toolFields = selectToolFields(state);
    const selectedTool = selectSelectedTool(state);

    let response = await fetch(`${endPoint}/get-report/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken.toString()}`,
      },
      body: JSON.stringify(dataToSend)
    })
    response = await response.json()
    if (response.error) {
      console.log("Error is ", response.error);
      return []
    }
    return {
      report: response?.message,
      toolFields,
      selectedTool,
    };
  } catch (error) {
    toast.error("Failed to fetch report. Please try again.");
    return rejectWithValue(error.message);
  }
});

export const UpdateReportStatus = createAsyncThunk('updateReportStatus', async ({ id, status }, { rejectWithValue }) => {
  try {
    const tableName = process.env.REACT_APP_TABLE_NAME
    const endPoint = process.env.REACT_APP_AWS_API_ENDPOINT
    const dataToSend = {
      status: status,
      tableName: tableName,
      id,
    }

    const accessToken = await fetchAuthSessionWithLogout();
    if (!accessToken) {
      return
    }
    let response = await fetch(`${endPoint}/update-status/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken.toString()}`,
      },
      body: JSON.stringify(dataToSend)
    })

    const data = await response.json()
    if (data.statusCode === 200) {
      return { status: data?.status }
    }
    return {}
  } catch (error) {
    toast.error("Failed to update status. Please try again.");
    return rejectWithValue(error.message);
  }
});
