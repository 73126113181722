import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchAuthSessionWithLogout } from '../utils/authSession';
import { toast } from 'react-toastify';

export const FetchToolFields = createAsyncThunk('fetchToolFields', async (_, { rejectWithValue }) => {
    try {
        const endPoint = process.env.REACT_APP_AWS_API_ENDPOINT;
        const accessToken = await fetchAuthSessionWithLogout();
        if (!accessToken) {
            return
        }
        let response = await fetch(`${endPoint}/tool-fields/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken.toString()}`,
            },
            body: JSON.stringify({})
        })
        response = await response.json()
        if (response.error) {
            console.log("Error is ", response.error);
            return []
        }
        return response
    } catch (error) {
        toast.error("Failed to fetch tool fields. Please try again.");
        return rejectWithValue(error.message);
    }
});

export const FetchToolInputs = createAsyncThunk('fetchToolInputs', async (_, { rejectWithValue }) => {
    try {
        const endPoint = process.env.REACT_APP_AWS_API_ENDPOINT;
        const accessToken = await fetchAuthSessionWithLogout();
        if (!accessToken) {
            return
        }
        let response = await fetch(`${endPoint}/tool-inputs/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken.toString()}`,
            },
            body: JSON.stringify({})
        })
        response = await response.json()
        if (response.error) {
            console.log("Error is ", response.error);
            return []
        }
        return response
    } catch (error) {
        toast.error("Failed to fetch tool inputs. Please try again.");
        console.log('error1:', error)
        return rejectWithValue(error.message);
    }
});

export const FetchToolOutputs = createAsyncThunk('fetchToolOutputs', async (_, { rejectWithValue }) => {
    try {
        const endPoint = process.env.REACT_APP_AWS_API_ENDPOINT;
        const accessToken = await fetchAuthSessionWithLogout();
        if (!accessToken) {
            return
        }
        let response = await fetch(`${endPoint}/tool-outputs/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken.toString()}`,
            },
            body: JSON.stringify({})
        })
        response = await response.json()
        if (response.error) {
            console.log("Error is ", response.error);
            return []
        }
        return response
    } catch (error) {
        console.log('error2:', error)
        toast.error("Failed to fetch tool outputs. Please try again.");
        return rejectWithValue(error.message);
    }
});