import { createSlice } from '@reduxjs/toolkit'
import { FetchBackendReports, FetchReport, FetchReportTemplates, UpdateReportStatus } from './ReportsApis'
import { pageSize } from '../utils/constantVariables';

const initialState = {
  loading: false,
  reports: [],
  reportTemplates: [],
  fileUploading: false,
  allReports: {
    reports: [],
    totalPages: 0,
    totalCount: 0
  },
  processingReports: {
    reports: [],
    totalPages: 0,
    totalCount: 0
  },
  inProgressReports: {
    reports: [],
    totalPages: 0,
    totalCount: 0
  },
  completedReports: {
    reports: [],
    totalPages: 0,
    totalCount: 0
  },
  generatedReports: {
    reports: [],
    totalPages: 0,
    totalCount: 0
  },
  selectedReport: {},
  totalReports: 0,
  totalPages: 0,
  latestPayload: null,
  error: '',
  updating: false
}

const findAndReplaceReport = (reports, report) => {
  for (let index = 0; index < reports.length; index++) {
    if (reports[index].id === report.id) {
      reports[index] = report
      break;
    }
  }
}

export const reportSlice = createSlice({
  name: 'reportsSlice',
  initialState,
  reducers: {
    setReportUpdate: (state, action) => {
      state.updating = action.payload
    },
    setFileUploading: (state, action) => {
      state.fileUploading = action.payload
    },
    setSelectedReport: (state,action) => {
      state.selectedReport = {...action.payload}
    },
    setLatestPayload: (state, action) => {
      state.latestPayload = action.payload
    },
    UpdateReport: (state, action) => {
      const updatedReport = action.payload;
      const index = state.allReports.reports.findIndex(report => report.id === updatedReport.id);
      if (index !== -1) {
        state.allReports.reports[index] = updatedReport;
        state.processingReports.reports = state.processingReports.reports.filter(report => report.id !== updatedReport.id);
      }
    },
    removeReport: (state, action) => {
      const updatedReport = action.payload;
      state.processingReports.reports = state.processingReports.reports.filter(report => report.id !== updatedReport.id);
      state.allReports.reports = state.allReports.reports.filter(report => report.id !== updatedReport.id);
    }
  },
  extraReducers: (builder) => {
    builder.addCase(FetchBackendReports.pending, (state, action) => {
      const tabType = action.meta.arg.status
      switch (tabType) {
        case 'all':
          state.allReports.loading = true
          break;
        case 'processing':
          state.processingReports.loading = true
          break
        case 'generated':
          state.processingReports.loading = true
          break
        case 'in-progress':
          state.inProgressReports.loading = true
          break
        case 'completed':
          state.completedReports.loading = true
          break
        default:
          break;
      }
    })
    builder.addCase(FetchBackendReports.fulfilled, (state, action) => {
      const tabType = action.payload.tabType
      // console.log("Status ", tabType);
      const data = { ...action.payload?.data }
      const totalPages = Math.ceil(data.length / pageSize)
      switch (tabType) {
        case 'all':
          state.allReports.reports = [...data.data]
          state.allReports.totalCount = data?.length
          state.allReports.totalPages = totalPages
          state.allReports.loading = false
          break;
        case 'processing':
          state.processingReports.reports = [...data?.data]
          state.processingReports.totalCount = data?.length
          state.processingReports.totalPages = totalPages
          state.processingReports.loading = false
          break
        case 'generated':
          // console.log("inside generated ", data);
          state.generatedReports.reports = [...data?.data]
          state.generatedReports.totalCount = data?.length
          state.generatedReports.totalPages = totalPages
          state.generatedReports.loading = false
          break
        case 'in-progress':
          state.inProgressReports.reports = [...data?.data]
          state.inProgressReports.totalCount = data?.length
          state.inProgressReports.totalPages = totalPages
          state.inProgressReports.loading = false
          break
        case 'completed':
          state.completedReports.reports = [...data?.data]
          state.completedReports.totalCount = data?.length
          state.completedReports.totalPages = totalPages
          state.completedReports.loading = false
          break
        default:
          break;
      }
    })
    builder.addCase(FetchBackendReports.rejected, (state, action) => {
      const tabType = action.payload.tabType
      switch (tabType) {
        case 'all':
          state.allReports.loading = false
          break;
        case 'processing':
          state.processingReports.loading = false
          break
        case 'in-progress':
          state.inProgressReports.loading = false
          break
        case 'completed':
          state.completedReports.loading = false
          break
        default:
          break;
      }
    })
    builder.addCase(FetchReport.pending, (state, action) => {
      state.loading = true
    })
    builder.addCase(FetchReport.fulfilled, (state, action) => {
      const { report: response, toolFields, selectedTool } = action.payload;  
    
      if (response && response[0]) {
        const record = response[0];
        let report = record.report;
    
        if (report) {
          const currentToolFields =
            toolFields.find((tool) => tool.name === selectedTool)?.fields || [];
    
          currentToolFields.forEach((field) => {
            const placeholder = `placeholder_${field.name}`;
            const value = record[field.name] || ''; 
            report = report.replace(new RegExp(placeholder, 'g'), value);
          });
        }
        state.selectedReport = { ...record, ...{ report } }
        // Following are being set for newly processed reports that might have to be reflected on list page (i.e. on retry):
        findAndReplaceReport(state.allReports.reports, report)
        findAndReplaceReport(state.processingReports.reports, report)
      }
      state.loading = false
    })
    builder.addCase(FetchReport.rejected, (state, action) => {
      state.loading = false
    })
    builder.addCase(UpdateReportStatus.pending, (state, action) => {
      state.loading = false
    })
    builder.addCase(UpdateReportStatus.fulfilled, (state, action) => {
      if (action.payload) {
        state.selectedReport = { ...state.selectedReport, ...action.payload }
      }
      state.loading = false
      state.updating = false

    })
    builder.addCase(UpdateReportStatus.rejected, (state, action) => {
      state.loading = false
      state.updating = false
    })
    builder.addCase(FetchReportTemplates.pending, (state, action) => {
      state.loading = false
    })
    builder.addCase(FetchReportTemplates.fulfilled, (state, action) => {
      if (action.payload) {
        state.reportTemplates = action.payload?.templates
      }
      state.loading = false
    })
    builder.addCase(FetchReportTemplates.rejected, (state, action) => {
      state.loading = false
    })
  }
})

export const { setSelectedReport, UpdateReport, removeReport, setLatestPayload, setFileUploading, setReportUpdate } = reportSlice.actions

export const selectAllTemplates = (state) => state.reportsSlice?.reportTemplates
export const selectFileUploading = (state) => state.reportsSlice?.fileUploading
export const selectAllReports = (state) => state.reportsSlice?.allReports
export const seletecReportLoader = (state) => state.reportsSlice?.loading
export const selectProcessingReports = (state) => state.reportsSlice?.processingReports
export const selectInProgressReports = (state) => state.reportsSlice?.inProgressReports
export const selecteCompletedReports = (state) => state.reportsSlice?.completedReports
export const selecteGeneratedReports = (state) => state.reportsSlice?.generatedReports
export const selectAllReportsLoading = (state) => state.reportsSlice?.allReports?.loading
export const selectProcessingReportsLoading = (state) => state.reportsSlice?.processingReports?.loading
export const selectInProgressReportsLoading = (state) => state.reportsSlice?.inProgressReports?.loading
export const selectGeneratedReportsLoading = (state) => state.reportsSlice?.generatedReports?.loading
export const selecteCompletedReportsLoading = (state) => state.reportsSlice?.completedReports?.loading
export default reportSlice.reducer