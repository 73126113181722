import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectToolFields, selectSelectedTool, setSelectedTool, selectToolInputs, selectToolOutputs, selectLoading } from '@/redux/ToolsSlice';
import { FetchToolFields, FetchToolInputs, FetchToolOutputs } from '@/redux/ToolsApis';

export const useCurrentTool = () => {
  const dispatch = useDispatch();
  const selectedTool = useSelector(selectSelectedTool);
  const allToolFields = useSelector(selectToolFields);
  const allToolInputs = useSelector(selectToolInputs);
  const allToolOutputs = useSelector(selectToolOutputs);
  const loading = useSelector(selectLoading);
  
  const currentTool = useCallback(() => {
    return allToolFields?.find(tool => tool.name === selectedTool) || [];
  }, [allToolFields, selectedTool]);

  useEffect(() => {
    if (loading) return;

    if (allToolFields?.length === 0) {
      dispatch(FetchToolFields());
    }
    if(allToolInputs?.length === 0) {
      dispatch(FetchToolInputs());
    }
    if(allToolOutputs?.length === 0) {
      dispatch(FetchToolOutputs());
    }
    if (!selectedTool) {
      const path = window.location.pathname.split('/')[1];
      const tool = allToolFields?.find((tool) => {
        const word = tool.name.split(' ')[0].toLowerCase();
        return path === word;
      });
      
      if (tool) {
        dispatch(setSelectedTool(tool.name));
      }
    }
  }, [dispatch, allToolFields, selectedTool, allToolInputs, allToolOutputs, loading]);

  const currentToolFields = currentTool().fields || [];
  const generation_name = currentTool().generation_name || '';
  const toolId = currentTool().id || '';

  const toolInputs = allToolInputs?.find(tool => tool.id === toolId)?.inputs || [];
  const toolOutputs = allToolOutputs?.find(tool => tool.id === toolId)?.outputs || [];

  return { currentToolFields, toolId, generation_name, toolInputs, toolOutputs };
};