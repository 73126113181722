import React from 'react'
import { Tabs, TabsContent, TabsList, TabsTrigger } from './shadcn-ui/tabs'
import { Card, CardContent, CardFooter } from './shadcn-ui/card'
import { Label } from './shadcn-ui/label'
import { Input } from './shadcn-ui/input'
import { Button } from './shadcn-ui/button'
import { Slider } from './shadcn-ui/slider'
import { Link } from 'react-router-dom'

const TokenTab = () => {
  return (
    <Tabs defaultValue="buy-with-credit-card">
    <TabsList className="grid w-full grid-cols-2">
      <TabsTrigger value="buy-with-credit-card" className="font-semibold text-xs md:text-sm">Buy with Credit Card</TabsTrigger>
      <TabsTrigger value="buy-with-invoice" className="font-semibold text-xs md:text-sm">Buy with Invoice</TabsTrigger>
    </TabsList>
    <TabsContent value="buy-with-credit-card">
      <Card className="border-none shadow-none">
        <CardContent className="p-0">
          <div className='mt-3'>
            <Label htmlFor="tokenNumbers" className="font-semibold">Token Amount</Label>
            <Input id="tokenNumbers" className="font-semibold" defaultValue="0" placeholder="0" />
          </div>
          <div className='mt-3'>
          <Slider defaultValue={[33]} max={100} step={1} />
          </div>
          <div className='mt-3'>
            <p className='font-SuisseIntlLight font-medium text-[#505050] text-[12px]'>You will pay 0.03 CHF per word. 
            <Link className='text-black underline font-semibold ml-2'>Learn More</Link>
            </p>
          </div>
          
        </CardContent>
        <CardFooter className="w-full flex-none mt-3 p-0">
          <Button className='w-full'>Take Tokens</Button>
        </CardFooter>
      </Card>
    </TabsContent>
    <TabsContent value="buy-with-invoice">
      <Card className='border-none shadow-none'>
        <CardContent className="space-y-2 p-0">
        <div className='mt-3 mb-4'>
            <Label htmlFor="tokenNumbers" className="font-semibold">Token Amount</Label>
            <Input id="tokenNumbers" className="font-semibold" defaultValue="0" placeholder="0" />
          </div>
          <div className='mt-3'>
          <Slider defaultValue={[33]} max={100} step={1} />
          </div>
          <div className='mt-3'>
            <p className='font-SuisseIntlLight font-medium text-[#505050] text-[12px]'>You will pay 0.03 CHF per word. 
            <Link className='text-black font-semibold underline ml-2'>Learn More</Link>
            </p>
          </div>
          <div className='mt-3'>
            <Label htmlFor="email" className="font-semibold">Your Email</Label>
            <Input id="email" className="font-semibold" placeholder="example@gmail.com" />
          </div>
        </CardContent>
        <CardFooter className="p-0 mt-3">
          <Button className="w-full">Send Request</Button>
        </CardFooter>
      </Card>
    </TabsContent>
  </Tabs>
  )
}

export default TokenTab
