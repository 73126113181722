import React, { useEffect } from "react";
import { GradientBorder } from "../components/ui/GradientBorder";
import { Link } from "react-router-dom";
import scribeAi from '../images/SVG/scribe.svg'
import consultAi from '../images/SVG/consult.svg'
import meetingAi from '../images/SVG/meeting.svg'
import moreProduct from '../images/SVG/more.svg'
import { useDispatch } from "react-redux";
import { setSelectedTool } from '../redux/ToolsSlice';
import { FetchToolFields, FetchToolInputs, FetchToolOutputs } from "@/redux/ToolsApis";

const tools = [
  {
    toolName: "Scribe AI",
    value: 'scribe-ai',
    desc:'Real-time Transcriber & summerizor',
    icon: scribeAi,
    link: "/scribe",
  },
  {
    toolName: "Consult AI",
    value: 'consult-ai',
    desc:'Real-time Transcriber & summerizor',
    icon: consultAi,
    link: "/consult",
  },
  {
    toolName: "Meeting AI",
    value: 'meeting-ai',
    desc:'Automated meeting Transcriber',
    icon: meetingAi,
    link: "/meeting",
  },
];

const Home = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setSelectedTool(''));
    Promise.all([
      dispatch(FetchToolFields()),
      dispatch(FetchToolInputs()),
      dispatch(FetchToolOutputs())
    ]);
  }, [dispatch]);

  const handleToolClick = (value) => {
    dispatch(setSelectedTool(value));
  };

  return (
      <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-5 w-[90vw] md:w-[80vw] mt-4">
        {tools.map((tool, index)=>(
                <Link to={tool.link} onClick={()=> handleToolClick(tool.toolName)} className='flex w-full p-4 rounded-3xl bg-white md:flex-col md:justify-center justify-start gap-3 md:gap-0 items-center border border-gray-200'  key={index} >
              <GradientBorder>
                <div className="shadow-sm bg-white rounded-full md:p-4 p-3">
                  <img className="md:w-6 w-5 max-w-none" src={tool.icon} alt={tool.toolName} />
                </div>
              </GradientBorder>
              <div className="">
              <h1 className='font-semibold md:text-center md:mt-[16px] font-SuisseIntlLight md:text-[24px] text-[18px]'>{tool.toolName}</h1>
              <p className="mt-[3px] md:text-center text-left text-[#505050] text-[14px] font-SuisseIntlLight font-medium">{tool.desc}</p>
              <div className='md:mt-[16px]'data-tooltip-id='no-words' data-tooltip-content='No more words left. Please contact MPAssist.'>
              </div>
              </div>
                </Link>
        ))}
        <div className='flex w-full p-4 rounded-3xl bg-white md:flex-col opacity-50 gap-3 md:gap-0 md:justify-center justify-start items-center border-gray-200 border-dashed border-2'>
        <GradientBorder>
                <div className="shadow-sm bg-white rounded-full md:p-4 p-3">
                  <img className="md:w-6 w-5 max-w-none" src={moreProduct} alt='more product icon' />
                </div>
              </GradientBorder>
              <div className=""> 
              <h1 className='font-medium md:mt-[16px] md:text-[24px] text-[18px]'>More Products</h1>
              <p className="mt-[3px] text-[#505050] text-[14px] font-SuisseIntlLight font-medium">Coming Soon</p>
              <div className='md:mt-[16px]'data-tooltip-id='no-words' data-tooltip-content='No more words left. Please contact MPAssist.'>
              </div>
              </div>
        </div>
      </div>
  );
};

export default Home;
