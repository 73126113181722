
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import DarkLogo from '../images/logotype.png'
import UserIcon from '../images/user-icon.png'
import { Link, useNavigate } from 'react-router-dom';
import TextInput from '../components/ui/TextInput';
import Button from '../components/ui/Button';
import { GradientBorder } from '../components/ui/GradientBorder';
import { getCurrentUser, signIn, confirmSignIn } from 'aws-amplify/auth';

function Login() {
  
  const [password, setPassword] = useState('')
  const [email, setEmail] = useState('')
  const navigate = useNavigate()

  const handleSignIn = async (e) => {
    e.preventDefault()
    try {
      const { nextStep } = await signIn({ username: email, password: password });
      // if (!isSignedIn) {
      //   throw Error("The login details are incorrect. Please try again.")
      // }
      switch (nextStep.signInStep) {
        case 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED':
          await confirmSignIn({
            challengeResponse: password,
          });
          break;
        default:
          break;
      }
      const { userId } = await getCurrentUser();
      localStorage.setItem("user_id", userId)
      localStorage.setItem("email", email)
      localStorage.removeItem('session_expired');
      navigate("/")
   
    } catch (error) {
      toast.error('The login details are incorrect. Please try again.')
      console.error("Error signing in:", error.message);
    }
  };


  useEffect(() => {
    const checkSession = async () => {
      try {
        const localId = localStorage.getItem("user_id")
        if (localId) {
          const { userId } = await getCurrentUser();
          console.log("Local ID is ", localId);
          if (userId === localId) {
            navigate("/")
          }
        }
      } catch (error) {
          console.log("Inside the error ", error);
      }
    }
    checkSession();
  }, [navigate])


  return (
    <div className='w-full bg-[#FAFAFA] flex flex-col gap-2 md:gap-[70px] items-center sm:flex sm:justify-center font-SuisseIntl'>
      <div className='flex gap-3 items-center mt-12'>
        <img className='md:w-36 w-28 object-contain' src={DarkLogo} alt='logo not found' />
        <p className='text-black font-SuisseIntlLight font-medium text-[14px] md:text-[16px]'>Medical AI Suite</p>
      </div>
      <div className='w-[90vw] md:w-[440px] max-w-[440px] rounded-2xl px-0 bg-transparent md:bg-white p-5 shadow-sm md:p-[30px] mt-0 md:mt-10 text-center transition-all ease-in-out duration-150 flex flex-col justify-center items-center '>
        <GradientBorder>
          <div className="shadow-sm bg-white rounded-full px-4 py-4">
            <img width={'24px'} height={'24px'} src={UserIcon} alt='Login circle not found' />
          </div>
        </GradientBorder>
        <h4 className='mt-[20px] md:text-[24px] text-xl font-semibold font-SuisseIntlLight'>Login to your account</h4>
        <hr className='h-[0.5px] hidden md:flex bg-[#E5E7EC] mt-[20px] w-full' />
        <div className='w-full'>
          <form className='text-left' onSubmit={handleSignIn}>
            <div className='mt-[20px]' >
              <TextInput required={true} name={"email"} label={"Email"} placeholder={"Enter your email"} type='email' value={email} onChange={(e) => { setEmail(e.target.value) }} />
            </div>
            <div className='mt-[20px]' >
              <TextInput required={true} name={'password'} label={"Password"} placeholder={"Enter your password"} type='password' value={password} onChange={(e) => { setPassword(e.target.value) }} />
            </div>
            <div className='mt-[12px]'>
              <Link className='text-sm text-black underline mt-2' to='/reset-password'>Forgot Password?</Link>
              {/* <a href='mailto:hey@mpassist.ai' className='mt-7 text-[#525866] underline font-medium cursor-pointer'>Forgot password?</a> */}
            </div>
            <div className='mt-[10px]'>
            <Button className='font-medium' variant='default' type='submit'>Login</Button>
            <div className='text-black font-medium text-sm flex justify-center gap-2 mt-2'>
              <p>Don't have an account? </p><Link className='underline' to='/sign-up'>Sign Up</Link>
            </div>
            </div>
          </form>

        </div>
      </div>
    </div>
  )
}

export default Login
