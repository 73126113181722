import React from 'react'
import { GradientBorder } from './ui/GradientBorder'
import billingIcon from '../images/SVG/billingIcon.svg'
import TokenTab from './TokenTab'

const AddToken = () => {
  return (
    <div className='p-2 w-full'>
        <div className='flex justify-center items-center flex-col text-center'>
        <GradientBorder>
                <div className="shadow-sm bg-white rounded-full px-4 py-4">
                  <img width={'24px'} src={billingIcon} alt='recording mic ' />
                </div>
              </GradientBorder>
              <h1 className='font-semibold font-SuisseIntlLight mt-[16px] text-[24px]'>Add Tokens</h1>
              <p className='mt-[4px] text-[#505050] md:text-[16px] text-[14px] font-SuisseIntlLight font-normal'>Specify the number of tokens you want to purchase or send a request for invoice.</p>
        </div>
        <TokenTab/>
    </div>
  )
}

export default AddToken