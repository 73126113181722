import React from 'react'
import DarkLogo from '../images/logotype.png'
import { Card, CardContent, CardDescription, CardFooter, CardTitle } from '@/components/shadcn-ui/card'
import { GradientBorder } from '@/components/ui/GradientBorder'
import { Label } from '@/components/shadcn-ui/label'
import { Input } from '@/components/shadcn-ui/input'
import { Button } from '@/components/shadcn-ui/button'
import VerifyPassword from '../images/SVG/verifyPass.svg'
import { Link, useNavigate } from 'react-router-dom'
const VerifyPass = () => {
  const navigate = useNavigate()
  return (
    <div className='w-full h-[90vh] bg-[#FAFAFA] flex flex-col items-center font-SuisseIntl justify-between text-3xl font-bold text-gray-400 gap-[100px]'>
       <div className='flex gap-3 items-center mt-12'>
        <img width={'150px'} src={DarkLogo} alt='logo not found' />
        <p className='text-black font-SuisseIntlLight font-medium text-[16px]'>Medical AI Suite</p>
      </div>
      <Card className="mb-auto mt-14 w-[90vw] md:w-[440px] max-w-[440px] rounded-[20px]">
        <div className='flex justify-center mt-7 mb-4'>
      <GradientBorder>
          <div className="shadow-sm bg-white rounded-full px-4 py-4">
            <img width={'24px'} height={'24px'} src={VerifyPassword} alt='Login circle not found' />
          </div>
        </GradientBorder>
        </div>
        <CardTitle className="text-[20px] text-center w-full font-medium">Enter Verification Code</CardTitle>
        <CardDescription className="text-[13.67px] text-[#09090B] text-center w-full font-medium">We’ve sent a code to james@alignui.com</CardDescription>
      <CardContent className="mt-6 pb-0">
        <form>
          <div className="grid w-full items-center gap-4">
            <div className="flex flex-col space-y-1.5">
              <Label htmlFor="verify-code">Verification Code</Label>
              <Input id="verify-code" placeholder="Please enter your verification code" />
            </div>
          </div>
          <p className="text-[14px] font-medium underline mt-1 text-[#09090B]">Resend Password</p>
        </form>
      </CardContent>
      <CardFooter className="flex justify-center w-full flex-col">
        <Button variant="default" className="w-full"><Link to='/verify-password'>Reset Password</Link></Button>
        <p className='text-[14px] font-normal mt-4 text-[#09090B]'>Changed your mind? <Link onClick={()=> navigate(-1)} className='text-black font-medium underline'>Go Back</Link></p>
      </CardFooter>
    </Card>
    </div>
  )
}

export default VerifyPass