import React from 'react'
import DarkLogo from '../images/logotype.png'
import { Link } from 'react-router-dom'
import AddUserIcon from '../images/SVG/addUser.svg'
import { Card, CardContent, CardFooter,CardTitle } from '@/components/shadcn-ui/card'
import { Button } from '@/components/shadcn-ui/button'
import { Label } from '@/components/shadcn-ui/label'
import { Input } from '@/components/shadcn-ui/input'
import { GradientBorder } from '@/components/ui/GradientBorder'

const SignUp = () => {
  return (
    <div className='w-full h-[90vh] bg-[#FAFAFA] flex flex-col items-center font-SuisseIntl justify-between text-3xl font-bold text-gray-400 gap-[100px]'>
        <div className='flex gap-3 items-center mt-12'>
        <img width={'150px'} src={DarkLogo} alt='logo not found' />
        <p className='text-black font-SuisseIntlLight font-medium text-[16px]'>Medical AI Suite</p>
      </div>
      <Card className="mb-auto w-[90vw] md:w-[440px] max-w-[440px] rounded-[20px]">
        <div className='flex justify-center mt-7 mb-4'>
      <GradientBorder>
          <div className="shadow-sm bg-white rounded-full px-4 py-4">
            <img width={'24px'} height={'24px'} src={AddUserIcon} alt='Login circle not found' />
          </div>
        </GradientBorder>
        </div>
        <CardTitle className="text-[20px] text-center w-full font-medium">Create a new account</CardTitle>
      <CardContent>
        <form>
          <div className="grid w-full items-center gap-4">
            <div className="flex flex-col space-y-1.5">
              <Label htmlFor="name">Fullname</Label>
              <Input id="name" placeholder="Please enter your name" />
            </div>
            <div className="flex flex-col space-y-1.5">
              <Label htmlFor="email">Email</Label>
              <Input id="email" placeholder="Please enter your email" />
            </div>
            <div className="flex flex-col space-y-1.5">
              <Label htmlFor="password">Password</Label>
              <Input id="password" placeholder="Please enter your password" type="password" />
            </div>
          </div>
        </form>
      </CardContent>
      <CardFooter className="flex justify-center w-full flex-col">
        <Button variant="default" className="w-full">Sign Up</Button>
        <p className='text-[14px] font-normal mt-4 text-[#09090B]'>Already have an account? <Link to='/' className='text-black font-medium underline'>Sign in</Link></p>
      </CardFooter>
    </Card>
        

    </div>
  )
}

export default SignUp