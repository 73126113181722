import React, { useState } from 'react'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from './shadcn-ui/table';
import { DrawerTrigger } from './shadcn-ui/drawer';
import { RetryIcon, DisabledRetryIcon } from "./ui/SvgIcons";
import Loader from './Loader';
import { Tooltip } from 'react-tooltip';

const CustomTable = ({ selectedTabReports, columns, onClickFunction, handleRetry }) => {
  const [processingRows, setProcessingRows] = useState({});

  const getItemState = ({ status, id, error, report_fetched }) => {
    const state = (status === 'completed' && !report_fetched) ? 'failed' : status;
    const isRowLoading = processingRows[id];

    if (state === 'processing' || state === 'retry' || isRowLoading) {
      return (
        <div className="text-[14px] font-SuisseIntlLight font-normal flex items-center gap-1">
          <Loader />
          {/* <span>Processing</span> */}
        </div>
      )
    }
    else if (state === 'uploading' || (state === 'failed' && error === "Audio file missing")) {
      return (
        <>
          <Tooltip id="audio-not-available" place="top" />
          <div className="gap-1" data-tooltip-id="audio-not-available" data-tooltip-content="Audio not available">
            <button disabled className="bg-[#FAFAFA] rounded-full px-2.5 py-2.5 cursor-not-allowed">
              <DisabledRetryIcon />
            </button>
          </div>
        </>
      )
    }
    else if (state === 'failed') {
      return (
        <div>
          <button className="bg-[#FAFAFA] block hover:bg-[#F1F1F1] rounded-full px-2.5 py-2.5" 
            onClick={() => {
              setProcessingRows((prev) => ({ ...prev, [id]: true })); 
              handleRetry({ id });
              setTimeout(() => setProcessingRows((prev) => ({ ...prev, [id]: false })), 10000);
            }}
          >
            <RetryIcon />
          </button>
          {/* <p className="text-[14px] flex items-center sm:hidden font-SuisseIntlLight font-normal hover:underline"
          onClick={() => {
            setProcessingRows((prev) => ({ ...prev, [id]: true })); 
            handleRetry({ id });
            setTimeout(() => setProcessingRows((prev) => ({ ...prev, [id]: false })), 10000);
          }}
          > Press here to retry</p> */}
        </div>
      )
    }
    else if (state === 'uploading') {
      return (
        <div className="gap-1" data-tooltip-id="audio-not-available" data-tooltip-content="Audio not available">
          <button disabled className="bg-[#FAFAFA] hidden sm:block rounded-full px-2.5 py-2.5">
            <DisabledRetryIcon />
          </button>
          <p className="text-[14px] flex items-center sm:hidden font-SuisseIntlLight font-normal"> Press here to retry</p>
        </div>
      )
    }
  }

  return (
    <Table>
      <TableHeader>
        <TableRow>
          {columns.map((head, index) => (
            <TableHead
              className="capitalize text-[#71717A] font-SuisseIntlLight font-semibold lg:px-2 px-6"
              key={index}
            >
              {head.Header}
            </TableHead>
          ))}
        </TableRow>
      </TableHeader>
      <TableBody>
        {selectedTabReports.map((report, index) => {
          const isDisabled = 
            (report.status === 'completed' && !report.report_fetched) || 
            (report.error === 'Audio file missing');

          return (
            <TableRow
              key={index}
              className={`relative ${isDisabled && 'cursor-not-allowed'}`}
            >
              {columns.map((column) => (
                <TableCell key={column.accessor} className='lg:px-2 px-6'>
                  <DrawerTrigger
                    className={`w-full h-full text-left ${isDisabled && 'cursor-not-allowed'}`}
                    onClick={() => !isDisabled && onClickFunction(report.row_number)}
                    disabled={isDisabled}
                  >
                    {report.fields && report.fields[column.accessor] !== undefined ? (
                      report.fields[column.accessor]
                    ) : (
                      column.Cell
                        ? column.Cell({ value: report[column.accessor] })
                        : report[column.accessor]
                    )}
                  </DrawerTrigger>
                </TableCell>
              ))}
              <TableCell className="lg:px-2 px-6">
                {getItemState(report)}
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

export default CustomTable;
