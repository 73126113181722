import React, { useState, useEffect } from 'react'
import Logo from "../images/logotype.png"
import { Link, useLocation, useNavigate } from 'react-router-dom'
import MenuButton, { NavMenuButton } from './ui/MenuButton'
import { signOut } from 'aws-amplify/auth'
import { useSelector } from 'react-redux'
import { getWordCount } from '@/redux/UserSlice'
import { selectFileUploading } from '../redux/ReportsSlice'
import { useSubscription } from '../utils/SubscriptionContext'
import settingIcon from '../images/SVG/settingIcon.svg'
import { Dialog, DialogContent, DialogTrigger } from './shadcn-ui/dialog'
import AddToken from './AddToken'
import { DropdownMenu } from '@radix-ui/react-dropdown-menu'
import { DropdownMenuContent, DropdownMenuGroup, DropdownMenuItem, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuShortcut, DropdownMenuTrigger } from './shadcn-ui/dropdown-menu'
import { Button } from './shadcn-ui/button'
import AddTokensIcon from '../images/SVG/AddTokensIcon.svg'
import SettingsIcon from '../images/SVG/SettingsIcon.svg'
import LogOutIcons from '../images/SVG/LogOutIcon.svg'
import MenuIcon  from '../images/SVG/menuIcon.svg'
import CloseIcon from '../images/closeIcon.png'
import { selectSelectedTool } from '@/redux/ToolsSlice'

const Header = ({ payingCustomer }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const [menuClicked, setMenuClicked] = useState(false)
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const path = window.location.pathname 
  const uploading = useSelector(selectFileUploading)
  const handleNavigation = (route) => {
    if (subscriptionExpired) return;
    setMenuClicked(false)
    if (location.pathname.includes(route) && !uploading) {
      window.location.reload()
    }else{
      navigate(route)
    }
  }

  const handleLogout = async () => {
    await signOut()
    localStorage.removeItem('user_id')
    navigate('/')
  }

  const hanldeLogoClick = ()=>{
    if (!uploading) {
      navigate('/')
    }
  }

  useEffect(() => {
    if (menuClicked) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  }, [menuClicked]);

  const { daysLeft } = useSubscription();
  const isTrialEnded = daysLeft < 0;
  const subscriptionExpired = (isTrialEnded && !payingCustomer)

  const getVariant = ({ btnPath, uploading }) => {
    if (uploading && (btnPath === path)) {
      return 'selected-disabled'
    } else if (btnPath === path) {
      return 'selected'
    } else if (uploading || subscriptionExpired) {
      return 'disabled'
    } else {
      return 'default'
    }
  }
 
  const tokens = useSelector(getWordCount)
  const selectedTool = useSelector(selectSelectedTool)
  const isHeader = (path === '/' || path === '/settings')

  return (
    <div className={`relative`}>
      <div className={`relative z-0 mt-5 sm:mt-[20px] mb-[4px] px-4 py-3 md:p-5 w-[90vw] md:w-[80vw] bg-white border border-[#E4E4E7] header-shadow flex justify-between gap-[4px] items-center md:rounded-[20px] sm: ${menuClicked ? 'rounded-t-[20px] rounded-b-[4px] z-20' : 'rounded-[20px]'}`}>
        <div className={`flex items-center gap-4 ${uploading ? "cursor-not-allowed" : "cursor-pointer"}`} onClick={hanldeLogoClick}>
          <img className='md:w-36 w-28 object-contain' src={Logo} alt="logo not found" />
          <p className='text-black font-SuisseIntlLight font-medium pt-1 text-[14px] md:text-[18px]'>{ isHeader ? 'Medical AI Suite' : selectedTool}</p>
        </div>
        <div className='flex gap-3 items-center flex-wrap'>
          {tokens && <h2 className='md:flex hidden text-[16px] font-bold font-SuisseIntlLight'>{tokens?.toLocaleString('en')} Tokens</h2>}
          {/* <MenuButton className={`min-w-[100px] mx-1 ${subscriptionExpired ? 'cursor-not-allowed' : ''}`} variant={getVariant({ btnPath: '/add-report', uploading })} subname='+' name=' Add report' onClick={() => { handleNavigation('add-report') }} /> */}
          {/* <hr className='h-[16px] mx-1 border border-[#CED0D5]'/> */}
          <Dialog>
            <DialogTrigger>
              <MenuButton className='md:flex hidden' name="Add Tokens" variant='outline' />
            </DialogTrigger>
            <DialogContent className="rounded-3xl">
              <AddToken/>
            </DialogContent>
          </Dialog>
          <Link to='/settings' className='md:flex hidden'>
          <img src={settingIcon} alt="Settings Icon" />
          </Link>
          {/* <MenuButton className={`mx-1 ${subscriptionExpired ? 'cursor-not-allowed' : ''}`} name='Reports' variant={getVariant({ btnPath: '/scribe', uploading })} onClick={() => { handleNavigation('scribe') }} /> */}
          {/* <MenuButton className={' mx-1'} name='Logout' variant={uploading ? 'disabled' : ''} onClick={handleLogout} /> */}
        </div>
        <div className='flex md:hidden'>
        <DropdownMenu onOpenChange={(open) => setIsMenuOpen(open)}>
      <DropdownMenuTrigger asChild>
        <Button className='p-0 flex-none focus:border-none hover:bg-transparent' variant="ghost">
        <img src={isMenuOpen ? CloseIcon : MenuIcon} alt='Menu Icon' className='w-[14px] h-[14px] object-contain'/>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-[290px] relative right-4 top-3 rounded-[14px]">
        <DropdownMenuLabel>Menu</DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuGroup>
          <DropdownMenuItem>
            <img src={AddTokensIcon} alt="" />
            <span className='font-SuisseIntlLight font-semibold'>Add Tokens</span>
            <DropdownMenuShortcut>{tokens?.toLocaleString('en')} Tokens</DropdownMenuShortcut>
          </DropdownMenuItem>
          <DropdownMenuItem onClick={()=> navigate('/settings')}>
            <img src={SettingsIcon} alt="" />
            <span className='font-SuisseIntlLight font-semibold'>Settings</span>
          </DropdownMenuItem>
        </DropdownMenuGroup>
        <DropdownMenuSeparator />
        <DropdownMenuItem onClick={handleLogout}>
          <img src={LogOutIcons} alt="" />
          <span className='font-SuisseIntlLight font-semibold text-red-500'>Log out</span>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>

        </div>
      
      </div>
      <div className='hidden'>
        {menuClicked && <div className="fixed top-0 left-0 w-full h-full bg-gray-700 opacity-30 z-10 md:hidden" onClick={() => setMenuClicked(false)}></div>}
        <div className={`${menuClicked ? 'opacity-100' : 'opacity-0 hidden'} flex flex-col gap-[4px] p-[8px] md:hidden border absolute mt-[-15px] left-1/2 transform -translate-x-1/2 rounded-b-[20px] bg-white border-gray-200 nav-shadow w-[90vw] z-20`}>
          <NavMenuButton className={`rounded-[4px] text-black ${subscriptionExpired ? 'cursor-not-allowed' : ''}`} variant={uploading ? 'disabled' : getVariant({ btnPath: '/add-report' })} subname='+' name=' Add report' onClick={() => { handleNavigation('add-report') }} />
          <NavMenuButton className={`rounded-[4px] text-black ${subscriptionExpired ? 'cursor-not-allowed' : ''}`} variant={uploading ? 'disabled' : getVariant({ btnPath: '/scribe' })} name='Reports' onClick={() => { handleNavigation('scribe') }} />
          <NavMenuButton className='rounded-t-[4px] rounded-b-[12px] text-[#525866]' variant={uploading ? 'disabled' : ''} last={true} name='Logout' onClick={handleLogout} />
        </div>
      </div>

      {/* isTrialEnded && <TrialEndModal/> */}
    </div>
  )
}

export default Header
